.ava-input-select {
  display: inline-block;
}

.ava-input-select {
  margin-left: 1rem;
}

.form__input_button_group select,
.ava-input-select select:not([size]) {
  appearance: none;
  background: #fff right 10px center url('/img/downtriangle.svg') no-repeat;
  background-size: 12px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: .85rem 1rem;
  min-width: 300px;
  width: 100%;
}

.form__input_button_group select {
  width: auto;
}

.ava-input-select select[size] {
  border: 2px solid #ccc;
}

.ava-input-select select[size]:focus {
  background: #ffd;
}

.ava-input-select select[disabled] {
  filter: opacity(.5) saturate(0);
  cursor: not-allowed;
  background: #ccca
}