.destinations__checkupdated__results {
  min-height: 500px;
}

.destinations__checkupdated__results table {
  border-collapse: collapse;
  border: 1px solid var(--border-color);
  margin: 0 0 2rem;
  width: 100%;
  xtable-layout: fixed;
}

.destinations__checkupdated__results table + form {
  text-align: right;
}

.destinations__checkupdated__results td:last-child:not(empty) {
  text-align: center;
  color: var(--error);
}



/*
Not sure why this specific of a selector is necessary,
but somewhere / how master.css is overriding the lower specificity one.
*/
.destinations__checkupdated__results thead th {
  background: var(--bg-table-header);
  text-align: left;
  font-weight: bold;
}

.destinations__checkupdated__results tr:nth-child(even) {
  background: whitesmoke;
}

.destinations__checkupdated__results td,
.destinations__checkupdated__results th {
  border: 1px solid var(--border-color);
  padding: .5rem;
}

.destinations__checkupdated__results td:last-child {
  white-space: pre-line;
}

.destinations__checkupdated__results h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.destinations__checkupdated__noupdates {
  font-size: 3rem;
  margin: 3rem 0;
  min-height: 500px;
}
