.stations__view--withbreadcrumb header {
  display: block;
}

.stations__view--withbreadcrumb header > div {
  display: flex;
  align-items: center;
  flex: none;
}

.stations__view--withbreadcrumb header div h1 {
  flex: 1 1 90%;
  margin: 0
}
