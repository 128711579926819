.destinations__crt_status .LoaderSimple {

}

.destinations__crt_status {
  align-items: center;
  display: flex;
  line-height: 1;
  margin: 1rem 0;
}

.destinations__crt_status b {
  font-weight: 500;
}

.destinations__crt_status__message {
  display: inline-block;
  font-weight: 500;
  font-size: 1.4rem;
  text-transform: uppercase;
  padding: 5px;
  margin: 0 4px;
  border-radius: 3px;
}

.destinations__crt_status--approved .destinations__crt_status__message {
  background: var(--approved);
  color: #fff;
}

.destinations__crt_status--unsubmitted .destinations__crt_status__message {
  background: var(--unsubmitted);
}

.destinations__crt_status--rejected .destinations__crt_status__message {
  background: var(--rejected);
  color: #fff;
}

.destinations__crt_status__loader {
  display: inline-block;
}

.destinations__crt_status__loader {
  height: 3rem;
  margin: 0 0 0 -4px;
}


.destinations__crt_status__loader svg {
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: scale(.8);
}
