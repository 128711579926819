[id=ChannelsEditTags] form {
  width: 50%;
}

[id=ChannelsEditTags] textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  padding: 1rem;
  width: 100%
}