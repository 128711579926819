[id=EpisodeMoveModal__menu] {
  margin: 3rem 0;
}

[id=EpisodeMoveModal__menu] label {
  font-size: 2rem;
}

[id=EpisodeMoveModal__menu] p {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
}

[id=EpisodeMoveModal__menu] button:first-of-type {
  margin-right: 1rem;
}

[id=EpisodeMoveModal__menu] .ava-input-select {
  display: block;
}

[id=EpisodeMoveModal__menu] select {
  border: 2px solid #ccc;
  display: block;
  width: 100%
}

[id=EpisodeMoveModal__menu] option {
  padding: .5rem;
}