[id=search_form] {
  padding: 2rem 0;
}

[id=search_form] label {
  display: block
}

[id=search_form] label::after,
[id=search_form] .label::after {
  content: ': ';
}

[id=search_form] .label {
  color: inherit;
}

[id=search_form] [type=date] {
  width: 100%;
}

.search__form__shelf {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -1rem;
}

.search__form__shelf > div:not(:last-child) {
  flex: 1 1 auto;
}

.search__form__shelf > * {
  margin: 1rem 2rem 0 0 !important;
}

.search__inchannels {
  display: inline-flex;
  flex-direction: column;
}

.search__inchannels > * {
  margin: auto auto .5rem;
}

[id=search_form] [type=reset] {
  background: var(--bg-table-header);
}

[id=search_form] [type=reset],
[id=search_form] [type=submit] {
  flex: 0 0 auto;
  margin: 0 0 0 2rem;
}


[id=search_form] .trigger__fauxlink {
  flex: 0 0 auto;
}

.show__more {
  justify-content: space-between;
  align-items: center;
}

.search__form__advanced {
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.search__form__advanced--open {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  height: auto;
  max-height: 900px;
  padding-top: 2rem
}

[id=search_form] .ava-input-select {
  margin: 0;
}

.search__form__chancount {
  margin: 1rem 0;
}

.search__form__chancount b {
  font-weight: bold;
  font-style: normal;
  font-size: 1.5em;
}

.search__form__controls {
  justify-content: flex-end;
  margin: 2rem 0 0 0;
}