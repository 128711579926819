[id=SwitchProjectsMenu] {
  align-content: start;
  box-sizing: border-box;
  background: var(--brand-secondary-dark-35);
  color: #fff;
  display: grid;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1.5rem;
  width: 30vw;
  z-index: 1;
  grid-template-rows: 2rem repeat(3, auto);
  grid-gap: 2rem;
  gap: 2rem;
  transform: translateX(30vw);
}

[id=SwitchProjectsMenu].open {
  transform: translateX(0);
}

.switch__project__ctrl {
  padding: 0;
  background: inherit;
  border: 0;
  justify-self: end;
}

.switch__project__ctrl svg {
  width: 2rem;
  height: 2rem;
  display: block;
  pointer-events: none;
}