.destinations__run__date {
  border: 2px solid var(--alternate);
  border-width: 2px 0;
  display: flex;
  align-items: center;
  margin: 0 0 2rem;
  justify-content: space-between;
  padding: 2rem 1rem;
}

.destinations__run__date label {
  margin: 0
}

.destinations__run__date input {
  margin-left: 1rem;
}

.destinations__run__date p:first-child {
  margin: 0
}

.destinations__run__date__full {
  font-size: 2rem;
  font-weight: 300;
  margin: 0 0 0 1rem;
}

