[id=DestinationSearchResults] q {
  font-weight: 800;
  font-style: italic;
}

.destination__search_videosets {
  margin: 2rem 0;
}

.destination__search_videosets table {
  width: 100%;
}

.destination__search_videosets h4 {
  padding: 0 0 1rem;
  border-bottom: 2px solid var(--border-color);
  margin-bottom: 0;
}

.destination__search_videosets tr:nth-child(even) {
  background: var(--alternate);
}

.destination__search_videosets td {
  padding: 1rem .5rem;
}

.destination__search_videosets td:last-child {
  text-align: right;
}

.destination__search_videosets td:first-child {
  width: 80%;
}

.destination__search_videosets a:not(.btn) {
  display: block;
}
