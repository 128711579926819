[class^=tools__] {
  margin: 4rem auto 4rem 18rem;
  max-width: 1280px;
}

[class^=tools__] .alert-warning {
  color: hsl(38, 40.1%, 22%);
}

.tools__imported ul {
  margin: 4rem 0;
}

.tools__imported li {
  display: grid;
  margin: 1rem 0;
  grid-template-columns: 25rem auto auto;
}

.tools__failed,
.tools__imported li {
  font-size: 2.2rem;
}

.tools__failed li {
  padding-left: .5rem;
  margin-left: 2rem;
  list-style: square;
}

.tools__failed > * + * {
  margin: 3rem 0;
}

.tools__imported .tools--toggle {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: flex-start;
}

.tools--toggle label {
  padding: .5rem 2rem;
}

.tools__failed,
.tools__imported li {
  font-size: 2.2rem;
}

.tools__failed li {
  padding-left: .5rem;
  margin-left: 2rem;
  list-style: square;
}

.tools__failed > * + * {
  margin: 3rem 0;
}

.tools__imported__controls {
  display: inline-flex;
  margin: 0;
}

.tools__imported__controls button:nth-child(2) {
  margin: 0 1rem;
}

.ToolsAddToGoogleSheet {
  display: flex;
  flex: 1 0 20rem;
  align-items: center;
  justify-content: center;
}

.ToolsAddToGoogleSheet .LoaderSimple {
  background: transparent;
  display: block;
  height: 2.5rem;
  width: auto;
  margin: 0
}

.ToolsAddToGoogleSheet .LoaderSimple svg {
  height: 100%;
  width: auto;
}

.tools__pushed__link,
.tools__pushed__link + * {
  margin: 0 2rem 0 0;
  font-size: 2rem;
}
