
/* VIDEO SHARED */

.youtubeModalVideo
{
	height: 100%;
}

.resultItemLink
{
	margin-top: 4px;
	margin-bottom: 4px;
	color: var(--brand-primary-dark);
	text-decoration: none;
	cursor: drag;
}

.resultItemLink:hover,
.resultItemLink:focus {
	color: black;
	text-decoration: none;
}

.resultItemLink:disabled,
.resultItemLink:disabled:hover {
  color: #666;
}

.resultItemLink:focus {
	outline: 2px solid orange;
}

.resultItemLink:disabled {
  opacity: .5;
}

.resultItemLinkDisabled
{
	pointer-events : none;
}

.videoOptionLabel
{
	margin-bottom: 6px;
	margin-top:    6px;
	font-size: 		12px;
	font-weight:   bold;
	color:         var(--brand-primary-dark);
}

.listText {
	color: #2d3a42dd;
  font-weight: normal;
}

.textLink
{
	cursor: pointer;
}

.textLink:hover
{
	color: black;
}


.videoResultItemHeader {
	min-height: 38px;
	margin-top: 0;
	color: black;
	text-decoration: none;
	cursor: grab;
}

.videoResultItemHeader:hover
{
	color: var(--brand-primary-dark);
	text-decoration: none;
}

.noUnderline
{
	text-decoration: none;
}
