.genres__list {

}

.genres__list li {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.genres__list a {
  flex: 1 1 90%
}

.genres__list {
  margin: 3rem 0;
}

.genres__list li:nth-child(odd) {
  background: var(--alternate);
}

.genres__list a {
  display: block;
  font-weight: bold;
  padding: 1.5rem 1rem;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.genres__list li:hover {
  background: hsla(201.7, 50.4%, 73.1%, .5);
}

.genres__list li:hover button {
  background: var(--button-bg--hover);
}

.genres__list li:hover a {
  color: black;
  text-decoration: underline wavy black;
}