.page__set__password {
  max-width: 60rem;
  margin: 5rem auto;
  line-height: 1.5;
}

.page__set__password header {
  margin-bottom: 3rem;
}

.page__set__password label,
.page__set__password input {
  display: block;
  width: 100%;
}

.page__set__password .set__pass__note {
  background: transparent;
  font-style: normal;
  padding: 0;
  margin: 0;
  padding: 0;
}

.password__strength {
  margin: 0 0 0 1ch;
  flex: 1 0 auto;
}

.set__pass__passwordindic {
  align-items: center;
  display: flex;
  font-weight: bold;
  line-height: 1;
  margin: 2rem 0 3rem;
}

meter {
  border-radius: 1000rem;
  overflow: hidden;
  background: #ccc;
}

/*
In Webkit/Blink browsers, -webkit-meter-bar is the background track
*/

/* WebKit */

meter::-webkit-meter-inner-element {
  background: none;
  display: flex;
}

meter::-webkit-meter-bar {
  background: #ccc;
  border-radius: 1000rem;
  overflow: hidden;
  border: 0;
}

meter::-webkit-meter-optimum-value {
  background: green;
  border-radius: 1000rem;
}

meter::-webkit-meter-suboptimum-value {
  background: red;
  border-radius: 1000rem;
}

/* Firefox */
meter::-moz-meter-bar {
  border-radius: 8px;
  background: green;
}

meter:-moz-meter-optimum::-moz-meter-bar {
  background: green;
}

meter:-moz-meter-sub-optimum::-moz-meter-bar {
  background: red;
}

.set__pass__approvable {
  display: flex;
  align-items: center;
}

.set__pass__approved .icon__approved,
.set__pass__approved .icon__rejected {
  height: 3rem;
  width: auto;
}

.set__pass__approved {
  line-height: 1;
}

.icon__approved path {
  fill: var(--approved);
}

.icon__rejected path {
  fill: var(--rejected);
}

.page__set__password .btn--request {
  position: absolute;
  right: 0;
  top: -0.5rem;
}

.page__set__password form {
  position: relative;
}