.destinations__reviewed {
  flex: 0 0 140px;
}

.destinations__reviewed label {
	font-weight: bold;
	color: #606060;
}

.destinations__reviewed > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}


.destinations__reviewed i {
  font-style: normal;
  flex: 0 0 5ch;
}


.destinations__curated {
  border-right: 1px solid var(--alternate);
  padding-right: 16px;
  margin-right: 16px;
}

