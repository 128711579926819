.reports__dropoffs__searchdates {
  display: flex;
  align-items: center;
}

.reports__dropoffs__searchdates .input-date {
  flex: 1 0 30rem;
  max-width: 30rem;
}

.reports__dropoffs__searchdates input {
  width: 100%;
}

.reports__dropoffs__searchdates button {
  margin-top: 4px;
}

.reports__table__videodropoffs th:nth-child(n+3):not(:last-child){
  white-space: nowrap;
}