.search__results__panel {
  padding: 3rem 0 3rem 0;
  border-top: 1px solid var(--alternate);
}

.search__results__panel {
  summary {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    
    &::after {
      content: '▶︎' / '';
      font-size: 2rem;
      color: #275d8b;
    }
  }
  
  &[open] summary::after {
    content: '▼' / '';
  }

  h2 {margin-block: 0}

}

.search__results__results {
  border-block: 2rem solid transparent;
}

.search__results__title q {
  font-weight: 550;
}

.button__toggle {
  background: var(--bg-table-header);
  border: 0;
  border-radius: .3rem;
  display: block;
  padding: .5rem 1rem;
  transition: transform 250ms ease-in-out;
}

.search__results__panel button:disabled,
.search__results__panel button[disabled] {
  opacity: .5;
}

.button__toggle b {
  display: inline-block;
  transition: inherit;
  speak: none;
}

.button__toggle:not(:disabled,[disabled]):hover {
  color: var(--brand-primary);
  background: #ccc;
}


.search__results__panel--open .button__toggle b {
  display: inline-block;
  transform: rotate(.25turn);
  transition: inherit;
}
