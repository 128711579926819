.update__account > form {
  max-width: 60rem;
  margin: auto;
}

.update__account form p {
  margin: 2rem 0
}

[type=password] + span:not([hidden]) {
  display: block;
  margin: 1rem 0 0;
  padding: .5rem 1rem;
  border-radius: .5rem;
}

[aria-describedby] + [id=pass_display] {
  font: normal 1.5rem / 1 monospace;
  letter-spacing: .1rem;
  background: #eeea;
}

.update__account fieldset {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.update__account fieldset p {
  flex: 0 1 49%;
}

.update__account legend {
  border-top: 1px solid var(--border-color);
  border-bottom: 0;
  padding: 1rem 0 0;
}

[action="api/useredit"] .btn--request {
  float: right;
}

[action="api/useredit"] [for=password1] {
  margin-bottom: 1rem;
}
