.pagination__component {
  display: inline-flex;
}

.pagination__list {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.pagination__component [disabled] {
  opacity: .5;
}

.pagination__component button {
  background: var(--button-bg);
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.pagination__component button:first-of-type {
  margin-right: 1rem;
}

[class^=pagination__]:not(.pagination_pg)::before,
[class^=pagination__]:not(.pagination_pg)::after {
  display: flex;
  align-items: center;
  line-height: inherit;
  font-size: 1rem;
}

.pagination__previous::before {
  content: '◀';
  content: '◀' / ''; /* The / '' part is empty alternative text. */
  padding-right: .75rem;
}

.pagination__next::after {
  content: ' ▶︎ ';
  content: ' ▶ ' / '';
  padding-left: .5rem;
}

.pagination__first::before {
  content: '︎◀◀';
  content: '︎◀◀' / ''; /* The / '' part is empty alternative text. */
  padding-right: .75rem;
}

.pagination__last::after {
  content: ' ▶︎▶︎ ';
  content: '  ▶︎▶︎' / '';
  padding-left: .5rem;
}

.pagination__btn {
  display: flex;
  align-items: center;
}

.channels__pagination_bar {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 2rem 0;

}

.channels__pagination {
  display: flex;
  justify-content: flex-end;
}

.pagination__component--bar {
  display: flex;
  margin: 2rem 0;
  justify-content: flex-end;
}

.pagination__pg {
  background: transparent;
  border: 1px solid #ccc;
  padding: 10px 20px;
}

.pagination__pg:nth-child(even) {
  border-width: 1px 0;
}

.pagination__pg:first-child {
  border-radius: 3px 0 0 3px;
}

.pagination__pg__current {
  background: var(--button-bg);
}

.pagination__pg:hover {
  background: var( --button-bg--hover);
  text-decoration: underline;
}

.pagination__pg:disabled {
  filter: opacity(.5) saturate(0);
  background: var(--bg-table-header);
  text-decoration: none !important;
  cursor: not-allowed;
}

.pagination__component--bar > button:last-child {
  border-radius: 0px 3px 3px 0;
  border-right-width: 1px;
}
