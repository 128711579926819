.episodes__display__table {
  border: 1px solid var(--alternate);
  margin: 2rem 0;
  width: 100%;
}

.episodes__display__table tr:nth-child(even) {
  background: var(--alternate);
}

.episodes__display__table th {
  font-weight: bold;
  background: #ccca;
}

.episodes__display__table th,
.episodes__display__table td {
  padding: 1rem;
  border: 1px solid var(--border-color);
}

/* Keep these lines in this order. Otherwise you'll mess up the cascade */
.episodes__display__table th:first-child {
  width: 40%;
}

.episodes__display__table td:nth-child(n + 3) {
  text-align: right;
}

.episodes__display__table th:nth-child(n + 3),
.episodes__display__table td:nth-child(n + 5) {
  text-align: center;
  white-space: nowrap;
}


.episodes__display__batch button {
  float: right;
}

.episodes__display__batch::after {
  content: ' ';
  display: table;
  clear: both
}

.episodes__list__dup {
  display: inline-block;
  border-left: 2px solid #ccc;
  padding-left: 1rem;
  margin-left: 1rem;
}

