@keyframes flashy {
  from {
    opacity: 1;
  }
  to {
    opacity: .5;
  }
}

.LoaderSimple {
  background: #fff;
  height: 550px;
  display: flex;
  width: 100%;
}

.LoaderSimple svg {
  display: block;
  margin: auto;
  transform: scale(.5);
  width: inherit;
  height: inherit;
}

.LoaderSimple rect {
  animation: flashy 600ms infinite;
  fill: #f36c23;
}

/* top */
.LoaderSimple rect:first-of-type {
}
/* left */
.LoaderSimple rect:nth-of-type(2) {
  animation-delay: 700ms;
}
/* right */
.LoaderSimple rect:nth-of-type(3) {
  animation-delay: 300ms;
}
/* bottom */
.LoaderSimple rect:nth-of-type(4) {
  animation-delay: 500ms;
}
