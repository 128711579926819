[id=DestinationsSearch] {
  margin: 1.5rem 1rem 3rem 0;
}

[id=DestinationsSearch] p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

[id=DestinationsSearch]::after {
  content: ' ';
  display: table;
  clear: both;
}

[id=DestinationsSearch] label[for=q] {
  display: block;
}

[id=DestinationsSearch] .trigger__fauxlink {
  margin-top: 0;
  float: right;
}