:root {
  --brand-primary: hsl(19, 98%, 58%); /* Orange. */
  --brand-secondary: hsl(202, 50%, 73%); /* Light blue */

  --brand-primary-dark: hsl(17, 73%, 53%);
  --brand-primary-dark-25: hsla(17, 73%, 53%, .5);

  --brand-primary-dark: hsl(17, 73%, 53%);
  --brand-primary-25: hsla(19, 98%, 58%, .5);

  --brand-secondary-dark-25: hsl(208, 56%, 25%); /* dark blue */
  --brand-secondary-dark-45: hsl(208, 56%, 45%);
  --brand-secondary-dark-35: hsl(208, 56%, 35%); /* new menu blue */

  --text-default: #000;
  --text-light: #fff;
  --text-dark: hsla(200, 0%, 0%, .7); /* dark blue */

  --brand-secondary--25: hsla(202, 50%, 73%, .25);
  --brand-secondary--80: hsla(202, 50%, 73%, .8);

  --link: hsl(201, 50%, 40%);
  --link--hover: hsl(201.7, 50.4%, 23.1%);

  --button-bg: hsla(201, 50%, 73%, 0.8); /* Light blue */
  --button-bg--hover: hsl(18.7, 98.1%, 78.8%); /* Light orange */
  --button-neutral: hsl(0, 0%, 87.5%); /* #dfdfdf / light gray */
  --button-success: hsla(18.9, 98.1%, 58%, .5);
  --button-destructive: hsla(201, 10%, 85%, 1);

  --border-color:  var(--button-neutral); /* #dfdfdf */
  --border-width: 2px;
  --bg-table-header: #ddd;

  /* MEOW and CRT badge colors */
  --approved: #080;
  --rejected: #d00;
  --error: #d00;
  --escalate: #d26400;

  --approved-badge: #0902;
  --rejected-badge: #d002;
  --error-badge: #9002;
  --escalate-badge: hsla(46.5, 100%, 79%, 0.7);

  --unsubmitted: hsla(45, 100%, 51.4%, 0.8);
  --alternate: #eee;
  --row-hover: var(--escalate-badge);

  --form-focus: #ff02;

  --success-bg: hsla(134, 41%, 88%, 1) /* Mint green: #d4edda */;
}