.video__info__note {

}

.video__info__note label {
  display: block;
}

.video__info__note textarea {
  display: block;
  width: 100%;
  resize: vertical;
}

.video__info__note p:last-child {
  text-align: right;
}