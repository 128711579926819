.fancy__radio {
  position: relative;
}

.fancy__radio label {
  line-height: 1;
  margin: 0;
  display: inline-flex;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
}

.fancy__radio [type=radio] {
  position: absolute;
  opacity: 0;
  left: -9999rem;
}

.fancy__radio label::before,
.fancy__radio [type=radio]:checked + label::after {
  border-radius: .3rem;
  height: 2rem;
  width: 2rem;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 1px #3330;
  background: #fff;
  content: '\00a0';
  text-align: center;
  margin-right: 1rem;
  border-radius: 1000rem;
}

.fancy__radio [type=radio]:checked + label::after {
  position: absolute;
  left: 0;
  transform: scale(.6);
  background: #3b99fc;
  color: #fff;
}

.fancy__radio [type=radio]:checked:disabled + label::before {
  opacity: .5;
  color: rgba(255,255,255,.8);
}

.fancy__radio:focus-within [type=radio] + label::before {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}


@supports (filter: saturate(0)) {
  .fancy__radio [type=radio]:checked:disabled + label::before {
    filter: saturate(0);
    opacity: 1;
  }
}

