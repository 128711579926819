.useradmin__table {
  border: 1px solid var(--border-color);
  border-collapse: collapse;
  width: 100%
}

.useradmin__table tr:nth-child(even) {
  background: var(--alternate);
}

.useradmin__table tr {
  border: 1px solid var(--border-color);
}

.useradmin__table thead {
  border-bottom: 3px solid var(--border-color);
}

.useradmin__table th {
  font-weight: bold;
  vertical-align: top;
  background: #eee5;
}

.useradmin__table th,
.useradmin__table td {
  padding: 1rem;
  border: 1px solid var(--border-color);
}

.useradmin__edit__controls {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 0 .5rem;
}

.useradmin__edit__controls .btn {
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0 .5rem;
}

.btn__user--activate {
  min-width: 10rem;
}

.useradmin__table ul {
  line-height: 2;
  margin: 0;
}

.useradmin__table .btn {
  margin: 0;
}

.useradmin__filter {
  display: block;
  font-weight: normal;
  width: 100%;
  margin: .5rem 0 0 0
}

.useradmin__table__wrap {
  min-height: 10rem;
  margin-bottom: 3rem;
}

.useradmin__filter label {
  position: absolute;
  left: -9999px;
}
