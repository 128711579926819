.form__select__wrapper {
  display: flex;
  align-items: stretch;
	margin-bottom: 1.5rem;
}

.destinations__select {
  display: grid;
  grid-template-columns: 85% 1fr 1fr;
  margin-bottom: 1.5rem;
}

.destinations__pulldown--nodelete {
  flex-grow: 1;
}

.destinations__select .Select-placeholder {
  color: inherit;
}

.deleteBtns,
.delete__btn,
.destinations__select__button {
  background: transparent;
  color: #df6232;
  border: 0;
  padding: 0;
  text-align: center;
  padding: 0;
  margin: 0 16px;
  white-space: nowrap;
}

.destinations__select__button:disabled,
.deleteBtns:disabled {
  opacity: .5;
}

.destinations__date_select {
  display: flex;
  align-items: center;
}

.destinations__component--videos {
  border: 1px solid var(--border-color);
  border-width: 1px 0;
  padding: 1rem 0;
  
  h5 {
    font-size: 1.6rem;
  }
}

[id=DestinationsSelect] {
  text-align: right;
}

.destinations__component > * + * {
  margin: 1rem 0
}

.destinations__component h5 {
  font-weight: bold;
}
.destinations__component__note {
  opacity: .8;
}

.react-datepicker__input-container + .fancy__chex {
  display: inline-block;
  margin: 0 1.5rem;
  flex: 0 1 20rem;
}

[id=create_date]:disabled {
  background: #ccca;
}
