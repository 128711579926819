[id=ChannelsVideosInAVA] .LoaderSimple {
  display: block;
  margin: auto;
}

[id=ChannelsVideosInAVA] table {
  border-bottom: 2px solid var(--border-color);
  line-height: 1;
  width: 100%;
}

[id=ChannelsVideosInAVA] thead {
  background: var(--bg-table-header);
  font-weight: bold;
}

[id=ChannelsVideosInAVA] th  {
  font-weight: bold;
}

[id=ChannelsVideosInAVA] th,
[id=ChannelsVideosInAVA] td {
  padding: 1rem
}

[id=ChannelsVideosInAVA] th:first-of-type {
  text-align: center;
}

[id=ChannelsVideosInAVA] tr {
  border-bottom: 1px solid var(--border-color);
}

[id=ChannelsVideosInAVA] img {
  display:block;
  width: 10rem;
  height: 7.5rem;
}

[id=ChannelsVideosInAVA] td:first-of-type {
  width: 12rem
}

[id=ChannelsVideosInAVA] tr:hover {
  background: var(--row-hover);
}

[id=ChannelsVideosInAVA] img {
  border: 1px solid var(--border-color);
}

.pagination__selpages {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination__selpages label {
  margin-right: 1rem;
}

.ChannelVideos__select select:not([size]) {
  min-width: 0;
  width: auto;
}

.channel__videos__active {
  text-align: center;
}

.channel__videos__active:not(:empty) {
  color: var(--error);
  font-weight: bold;
  font-size: 3rem;
}

[id=ChannelsVideosInAVA] ul {
  line-height: 1;
  margin: 0;
}

[id=ChannelsVideosInAVA] li + li {
  margin: 1rem 0;
}

