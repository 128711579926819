.breadcrumb__menu {
  display: flex;
  font-weight: bold;
  align-items: center;
  margin: 3rem 0 2rem;
}

.breadcrumb__menu li {
  color: hsl(201.7, 30%, 20%);
}

.breadcrumb__menu li:not(:last-child)::after {
  content: '\27A4';
  padding: 0 1.25rem;
  line-height: 1;
  align-content: center;
  transform: scale(.8);
  display: inline-flex;
}

.breadcrumb__selected {
  color: inherit;
  pointer-events: none;
  cursor: auto;
}