[id=ChannelsManageGroups] {
  border-top: 2px solid var(--border-color);
}

[id=ChannelsManageGroups__addtogroup] .ava-input-select {
  margin: 0 1rem 0 0;
}

[id=ChannelsManageGroups__addtogroup] .btn {
  padding: .9rem 3rem;
}

[id=ChannelsManageGroups__body] {
  display: grid;
  gap: 2rem;
  grid-template: "addtogroup removefromgroup" auto / 1fr 1fr ;
}

[id=ChannelsManageGroups__addtogroup] {
  grid-area: addtogroup;
}

[id=ChannelsManageGroups__remove] {
  grid-area: removefromgroup;
}

[id=ChannelsManageGroups__remove] h5 {
  margin: 0 0 1rem;
}

[id=ChannelsManageGroups__delete] {
  font-weight: 450;
}