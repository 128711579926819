.videoset__rundates .destinations__component__note {
  display: none;
}

.videoset__rundates .destinations__playlist {
  margin: 1rem 0;
}

.videoset__rundates .destinations__playlist__table {
  margin: 0 0 3rem;
}

[id=DestinationsCreateNewRunDate__success] p {
  font-size: 2rem;
}

.videoset__rundates summary,
.videoset__rundates > div:not(.LoaderSimple) {
  align-items: center;
  background: rgba(245, 245, 245, 0.2);
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(58%,100%) 4rem 0rem;
  gap: 1rem;
  padding: .5rem 1rem;
}

.videoset__rundates > div:not(.LoaderSimple) {
  grid-template-columns: auto 8rem;
}

.videoset__rundates:nth-child(odd) summary,
.videoset__rundates:nth-child(odd) > div:not(.LoaderSimple) {
  background: var(--alternate);
}

/* Firefox mostly */
/* Hack to serve styles to Firefox. It will some day fail, but until it does ... */
@supports (-moz-transform: none) {
  .videoset__rundates summary::after {
    background: var(--brand-secondary);
    border-radius: .5rem;
    padding: .8rem .5rem;
  }
}

/* Changes position of arrow */
.videoset__rundates ::-webkit-details-marker {
  background: var(--brand-secondary--80);
  border-radius: .5rem;
}

/* Hack for Safari 10.1+ that still works, thank goodness! */
@media not all and (min-resolution:.001dpcm) {
  button.videoset__edittitle {
    margin-right: 3rem;
  }
}

.videoset__rundates__title {
  display: flex;
  font-size: inherit;
  margin: 0;
  align-items: center;
}

.videoset__rundates__title b {
  flex: 1 1 85%;
  line-height: 1.5;
}

.videoset__rundates__title a:hover {
  color: var(--brand-secondary-dark-45);
  border-bottom: 1px solid var(--brand-primary-dark-25);
  text-decoration: none;
  background: hsla(208, 56%, 46%, .2);
}

button.videoset__edittitle {
  padding: 0 1rem;
  flex: 0 0 auto;
}

.destinations__videosets__msg b {
  font-weight: normal;
  margin-right: 1rem;
}

.videoset__rundates {
  border-top: 1px solid var(--border-color);
}

.videosets__list {
  margin: 0
}

.videosets__list li {
  display: grid;
  grid-template-columns: auto 6rem;
  padding: 1rem 3rem 1rem 3rem;
  text-align: right;
}

.videosets__list a {
  text-align: left;
}

.videosets__list li + li {
  border-top: 1px solid var(--border-color);
}

.videosets__list li:last-child {
  border-bottom: 2px solid var(--border-color);
}

.videoset__rundates .LoaderSimple {
  height: 5rem;
  margin: 1rem 0;
}

.videoset__rundates svg {
  transform: none;
  height: 100%;
}

.videosets__list li:hover {
  background: var(--row-hover);
}

.videoset__rundates__count {
  display: block;
  font-weight: normal;
  white-space: nowrap;
  font-size: 1.1rem;
  color: #666
}

.VideoSetsPerPage__select label {
  display: block;
}

[id=DestinationsVideoSetsToggleSortForm] .toggle__switch__group div {
  flex: 0 0 33%;
}

[id=DestinationsVideoSetsToggleSortForm] label {
  padding: .75rem;
}

[id=DestinationsVideoSetsToggleSortControl] {
  flex: 1 1 50%;
}

.stations__view .l-shelf-flex50 {
  align-items: center;
}