.video__list__table__sortbtn {
  background: transparent;
  border: 0;
  border-bottom: 1px dotted #ccc;
  padding: 0;

}

.video__list__table__sortbtn span {
  padding: 0 1rem;
}

.video__list__table_occurr {
  text-align: center;
  color: var(--error);
  font-weight: 450;
}

.video__list__table td:last-child {
  white-space: nowrap;
}