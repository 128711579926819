[id=destination__header] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.destinations__list {
  width: 100%;
}

.destinations__list thead td {
  font-weight: bold;
  background: var(--alternate);
}

.destinations__list tr:nth-child(even) {
  background: var(--alternate);
}

.destinations__list tr:hover {
  background: var(--row-hover);
}

.destinations__list td {
  padding: 1rem;
  white-space: nowrap;
  text-align: right;
}

.destinations__list td:first-child {
  width: 80%;
  text-align: left;
}

[id=DestinationsCreateNew] form {
  display: flex;
  align-items: flex-end;
  margin: 3rem 0;
}

.destinations__create-new {
  margin-right: 1rem;
  flex: 1 1 100%;
}

.destinations__create-new > * {
  display: block;
  width: 100%;
}

[id=DestinationsCreateNew] .btn {
  padding: .8rem 1.2rem
}

[id=destination_create_vidset] {
  margin-top: 3rem;
}

.vlm__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.vlm__controls > button {
  margin-left: 1rem;
}

.vlm__controls .toggle__switch,
.vlm__controls .toggle__switch__form {
  margin: 1rem 0;
}

.vlm__controls .form__error {
  flex: 1 1 60%;
}
