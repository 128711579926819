.useradmin__add__user {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-areas:  "a a a a"
                        "b b c c"
                        "d d e e"
                        "f f g g"
                        "f f h h"
                        "i i j j";
  grid-template-columns: repeat(4, 1fr);
  padding: 0 1rem 2rem;
}

.useradmin__add__user fieldset[disabled] {
  opacity: .5;
  background: #eee;
  padding: 1rem;
}

.useradmin__add__user fieldset[disabled]::before {
  content: 'This feature is a work in progress.';
}

.useradmin__add__use .fancy__chex {
  margin: 2rem 0;
}

.ua-au-a {
  grid-area: a;
}

.ua-au-b {
  grid-area: b;
}

.ua-au-c {
  grid-area: c;
}

.ua-au-d {
  grid-area: d;
}

.ua-au-e {
  grid-area: e;
}

.ua-au-f {
  grid-area: f;
}

.ua-au-g {
  grid-area:g;
}

.ua-au-h {
  grid-area: h;
}

.ua-au-i {
  grid-area: i;
  display: flex;
  justify-content: space-between;
}

.useradmin__add__user legend {
  margin: 0 0 2rem 0;
  font-weight: bold;
  font-size: 1.4rem;
  border-bottom: 0;
}

.user__admin__select {
  margin-left: 0;
  width: 100%;
}

.useradmin__add__user_wrapper header {
  display: flex;
  align-items: center;
  padding: 2rem 0;
}

.useradmin__add__user_wrapper h1 {
  flex: 1 1 90%;
}

.useradmin__add__user_wrapper--open .button__toggle {
  transform: rotate(90deg);
}

.useradmin__add__user_wrapper .action__message {
  margin-top: .5rem;
}

.user__admin__project {
  margin-bottom: 2.5rem;
}

.user__admin__project label {
  width: auto;
}

.user__admin__p_role {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-left: 3.25rem;
}

.user__admin__edit__user header {
  padding: 0 0 2rem;
}
.user__admin__edit__user h1 {
  margin-top: 0;
}

.user__admin__edit__user .useradmin__add__user {
  padding: 0;
}

.user__edit__controls {
  display: flex;
  justify-content: flex-end;
  x-justify-content: end; /* Unsupported in Chrome < 81 */
  margin-top: -4.5rem;
}

.user__edit__controls form {
   margin-right: 1rem;
}
