.episode__videolist > header {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.episode__videolist > header > *:first-child {
  flex: 1 1 80%
}

.episode__savestatus:not([hidden]) {
  background: #fc0a;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity 500ms linear 5s;
}

.should__be__acollapsible__panel button {
  margin-right: 1rem;
}

[id=episode__view__ratingcrt] {
  display: flex;
  justify-content: space-between;
}

.episode__view__controls {
  display: flex;
  justify-content: space-between;
}

.episode__view__controls > * {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.episode__view__controls button:not(:last-child),
.export__csv button {
  margin: 0 1rem 0 0
}

[id=EpisodeViewDeleteModal] [method=dialog] {
  margin: 3rem;
}

[id=EpisodeViewDeleteModal] [method=dialog] button {
  margin: 1rem 1rem 1rem 0;
  min-width: 10rem;
}

[id=EpisodeViewDeleteModal] .dialog__body {
  min-height: 30rem;
}