/* CSS for /channels */

.modalTags
{
  display:        inline-block;
  min-width:      10px;
  padding-left: 0;
  padding-bottom: 8px;
  padding-top: 3px;
  padding-right: 0;
  font-size:      14px;
  font-weight:    bold;
  color:          var(--brand-primary-dark);
  line-height:    1;
  vertical-align: middle;
  white-space:    nowrap;
  text-align:     center;
}

#channelHeader {
  margin-top:   20px;
}

#channelHeader form {
  position: relative;
}

#channelHeader input + div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

[id=channel_values_direction] {
  display: inline-block;
}

.channel__select__group {
  display: inline-flex;
  margin: 1rem 0 0;
  justify-content: flex-end;
}

.channel__select__group select {
  margin-right: 1rem;
}

.modalTagItem
{
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 469px;
  min-width: 10px;
  height: auto;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: var(--brand-primary-dark);
  line-height: 1;
  vertical-align: middle;
  white-space: normal;
  text-align: left;
}


.channelListDescription
{
  position: relative;
  font-size: 12px;
  width: 200px;
  height: auto;
  color: white;
  white-space: normal;
}

.channel__item__add {
  display: block;
  margin: .5rem 0;
  border: 0;
  border-radius: .3rem;
  padding: .5rem;
  width: 100%;
}

.channel__item_add_form {
  border-top: 1px solid var(--brand-primary-dark);
  border-bottom: 1px solid var(--brand-primary-dark);
  padding: 10px 0;
  margin: 10px 0;
}

.button__group form {
  display: inline-block;
  text-align: center;
}

.channels__group_menu {
  margin: 0;
  display: block;
  max-width: 40rem;
}

[id=add_channels_by_url_form] + div {
  display: flex;
  justify-content: space-between;
}

.channels__pgcount {
  font-size: 2rem;
}

.channels__list {
  margin-block-end: 2rem;
}
