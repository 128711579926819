.downloading__indicator.LoaderSimple {
  width: 3rem;
  height: auto;
  margin: 0 1rem;
}

.downloading__indicator.LoaderSimple svg {
  width: inherit;
  height: inherit;
  transform: none;
}

.reports__result_count {
  align-items: baseline;
  display: flex;
  margin: 2rem 0;
}

.reports__result_count span {
  font-size: 3.4rem;
}

.reports__result_count .download {
  background: transparent;
  border: 0;
  color: hsl(208, 56%, 46%);
  line-height: 1;
  padding: 0rem;
  margin-left: 2rem;
  display: inline-flex;
  align-items: center;
}

.reports__result_count .download b {
  text-decoration: underline;
}
.reports__result_count .download:disabled b,
.reports__result_count .download:hover:disabled b {
  color: hsl(208, 56%, 46%);
  text-decoration: none;
  filter: saturate(0);
  cursor: not-allowed;
  text-decoration: none;
}

.reports__result_count .download:hover {
  color: #df6232;
  text-decoration-color: #df6232;
}
