.badge__delete {
  background: var(--button-destructive);
  display: inline-flex;
  padding: 0 0 0 1rem;
  border-radius: .3rem;
  overflow: hidden;
  font-size: 1.6rem;
  margin: 0 1rem .5rem 0;
}

.badge__delete__btn {
  margin-left: 1rem;
  padding: .5rem 1rem;
  background: hsla(201, 10%, 75%, 1);
  border: 0;
}

.badge__delete:focus-within {
  background: var(--button-bg--hover)
}

.badge__delete:hover .badge__delete__btn,
.badge__delete:focus-within .badge__delete__btn {
  background: hsla(18.9, 98.1%, 58%, .7); /* Light orange */
}