.reports__channel_use_item {
  border-top: 1px solid #ddd;
}

.reports__channel_use_item:last-child {
  border-bottom: 1px solid #ddd;
}

.reports__channel_use_item:first-child {
  margin-top: 1rem;
}

.reports__channel_use_item b {
  font-weight: inherit;
}

.reports__channel_use_item summary {
  display: grid;
  grid-template-columns: 2fr 6ch auto;
  align-items: center;
  gap: 2rem;
  font-size: 1.6rem;
  font-weight: 420;
  padding: 1rem;
}

.reports__channel_use_item:nth-child(odd) summary {
  background: var(--alternate);
}

.reports__channel_use_item summary::after {
    content: '▶︎';
}

.reports__channel_use_item[open] summary::after {
  content: '▼';
}

.reports__channel_use_item:hover summary {
  background: var(--row-hover);
}

.reports__channel_use_item_freq {
  text-align: right;
}

.reports__channel_use_item > div {
  padding: 1rem 1rem 2rem 1rem;
}