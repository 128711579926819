/* CSS for channel/:id */

[id=channel_single_page_header] {
  display: grid;
  grid-template:  'title title' auto
                  'description image' auto
                  'secondhead image' auto
                  'meta image' auto / auto 27.6rem;
  gap: 2rem 5rem;
  max-width: 80%;
}

[id=channel_single_page_header] h1 {
  grid-area: title;
  font-size: 3.6rem;
  display: flex;
  align-items: flex-end;
  align-items: baseline;
  justify-content: space-between;
}

[id=channel_single_page_header] .channel__external {
  flex: 0 0 27.6rem;
}

.channel__single__thumb {
  grid-area: image;
  border: 2px solid var(--border-color);
  border-radius: 5px;
  overflow: hidden;
}

.channel__single__description {
  grid-area: description;
}

[id=channel_single_page_header] h2 {
  grid-area: secondhead;
  font-size: 2.4rem;
}

.channel__single__meta {
  grid-area: meta;
}

.channel__single__meta li {
  display: grid;
  grid-template-columns: 30rem auto;
}

.channel__single__meta li + li {
  margin-top: 1rem;
}

.channel__external {
  font-size: 1.4rem;
}

/*-=-=-=-=-=-=-=-=-=*/
.channel__singlepg__panel {
  padding: 0 1rem 2rem;
}

.channel__singlepg__panel + .channel__singlepg__panel {
  border-top: 2px solid var(--border-color);
}

.channel__singlepg__panel summary {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(58%,100%) 4rem 0rem;
  gap: 1rem;
  padding: 1rem 0 2rem 0;
}

.channel__singlepg__panel h2 {
  font-size: 2.4rem;
  margin: 0;
}

[id=ChannelPageDeleteConfirm] form > * + * {
  margin: 4rem 0;
}


/* Firefox mostly */
.channel__singlepg__panel ::marker {
  display: none;
}

/* Hack to serve styles to Firefox. It will some day fail, but until it does ... */
@supports (-moz-transform: none) {
  .channel__singlepg__panel summary::after {
    content: '\25b6';
    border-radius: .5rem;
    padding: 1rem .5rem;
    line-height: 1;
    text-align: center;
  }

  .channel__singlepg__panel[open] summary::after {
    content: '\25bc';
  }
}

/* Changes position of arrow */
.channel__singlepg__panel ::-webkit-details-marker {
  order: 3;
  padding: 1.5rem;
  background: var(--brand-secondary--80);
  border-radius: .5rem;
}

/* Hack for Safari 10.1+ that still works, thank goodness! */
@media not all and (min-resolution:.001dpcm) {
  .channel__singlepg__panel summary {
    position: relative;
  }

  /* Changes position of arrow */
  .channel__singlepg__panel ::-webkit-details-marker {
    position: absolute;
    right: .5rem;
    top: auto;
    bottom: auto;
  }

  button.videoset__edittitle {
    margin-right: 3rem;
  }
}




.channel__singlepg__panel__title {
  display: flex;
  font-size: inherit;
  margin: 0;
}

.channel__singlepg__panel__title b {
  flex: 1 1 85%;
  line-height: 1.5;
}
