.PasswordResetShowHide__item {
  display: grid;
  grid-template-columns: 3fr 10rem;
  border-radius: .5rem;
  border: 2px solid var(--border-color);
  overflow: hidden;
  flex: 1 0 100%;
  margin-right: 1rem;
}

.PasswordResetShowHide__item > * {
  border: 1px solid transparent;
  border-radius: 0;
}

.PasswordResetShowHide__item input {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 1.6rem
}
