.action__message {
  align-items: center;
  background: var(--success-bg);
  border-radius: .5rem;
  font-size: 2rem;
  margin: 2rem 0;
  padding: .75rem;
  display: flex;
  justify-content: center;
}

.action__message span {
  flex: 1 1 auto;
  text-align: center;
  white-space: pre-wrap;
}

.action__message--failure {
  filter: hue-rotate(210deg);
}

.action__message button {
  align-items: center;
  background: hsla(134, 41%, 38%, .25);
  border: 1px solid transparent;
  border-radius: 1000rem;
  display: flex;
  flex: 0 0 auto;
  padding: 1.5rem 1rem;
  line-height: 0;
}

.action__message a {
  text-decoration: underline;
}

.action__message button:hover {
  background: hsla(134, 41%, 38%, .8);
  color: #fff;
}

.action__message q {
  font-weight: bold;
}