.ReportsChannelUsageResultPanel__tbl {
  width: 100%;
}

.ReportsChannelUsageResultPanel__tbl thead {
  font-weight: 500;
  border-bottom: 2px solid var(--border-color);
}

.ReportsChannelUsageResultPanel__tbl td {
  font-weight: inherit;
  padding: 1rem 0;
}

.ReportsChannelUsageResultPanel__tbl tr:not(:last-of-type) {
  border-bottom: 1px solid var(--border-color);
}