.spreadsheet__import {
  margin: 3rem 0;
  max-width: 80%;
}

.spreadsheet__import label {
  display: block;
}

.spreadsheet__import > * + * {
  margin: 2rem 0 0 0;
}

.spreadsheet__destinations input,
.spreadsheet__import input,
.spreadsheet__import select {
  display: block;
  width: 100%
}

.spreadsheet__import .alert-warning {
  color: hsl(38, 40.1%, 22%);
}

.spreadsheet__import [aria-describedby] + span {
  display: block;
  margin-top: .5rem;
  font-style: italic
}

.spreadsheet__import__disabled,
.spreadsheet__import [disabled],
.spreadsheet__import *:disabled {
  filter: saturate(0) opacity(.5);
}

[id=tools_import_sheet] legend {
  border: 0;
  font-size: 2.4rem;
}

[id=tools_import_sheet] fieldset label {
  font-size: 1.8rem;
  font-weight: normal;
  margin: 1rem 0;
}

[id=ImportSpreadsheetToPlaylist] .toggle__switch {
  justify-content: flex-end;
}