[id=SiteHeader] {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem 2.5rem
}

[id=SiteHeader] [id=Logo1021] {
  flex: 0 1 auto;
  height: 6rem;
}

[id=Logo1021] svg {
  display: block;
  height: 100%;
  margin: 0;
  width: auto;
}

#account {
  flex: 0 0 auto;
  font-weight: bold;
  text-align: left;
}

#account a {
  color: var(--brand-secondary-dark-35);
  text-decoration: underline;
  padding: 0;
}

#account b {
  display: inline-block;
  padding: 0 1rem;
}
