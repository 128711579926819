.groups__table {
  border: 1px solid var(--border-color);
  width: 100%;
  border-collapse: collapse;
}

.groups__table :is(th,td) {
  padding: 1rem;
  border: 1px solid var(--border-color);
}

.groups__table tr:nth-child(even) {
  background: var(--alternate);
}

.groups__table thead {
  border-bottom: 3px solid var(--border-color);
}

.groups__table tbody tr:hover {
  background: var(--row-hover);
}

.groups__table .button__group {
  display: inline-flex;
  gap: 1rem;
}