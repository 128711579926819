.close__button--longform {
  display: flex;
  align-items: center;
  background: transparent;
  border-color: transparent;
  float: inline-end;
}

.close__button > * {
  pointer-events: none;
}

.close__button--longform svg {
  margin-right: .5rem;
  height: 1.2rem;
  width: auto;
}