.edit__modal__form {
  width:100%;
  margin: 3rem auto 1rem;
  padding-bottom: 5rem;
}

.edit__modal .close__button {
  display: block; 
  float: inline-end;
  background: transparent;
  border: transparent;
}

.edit__modal .close__button:hover,
.edit__modal .close__button:hover svg {
  color: var(--brand-primary);
  fill: var(--brand-primary);
}

.edit__modal__form label {
  display: block;
}

.edit__modal__form > div > div{
  display: flex;
}

.edit__modal__form input {
  flex: 1 1 80%;
  margin-right: 1rem;
}

.edit__modal__form button {
  margin-right: 1rem;
  flex: 1 1 80px;
}

.video__set__edit__error {
  color: var(--brand-primary-dark);
  margin: 1rem 0;
}

.edit__modal__form [type=reset] {
  background: hsl(0, 0%, 90%);
  border: 1px solid hsl(0, 0%, 90%);
}

.edit_modal__set__edit__error {

}

[id=edit_modal_dialog]{}