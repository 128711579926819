[id=AmzClustersAddNew] {
  clear: both;
  float: right;
  width: 100%;
  text-align: right;
  outline: 3px solid #ccc;
}

[id=AmzClustersAddNew]::after {
  content: ' ';
  display: block;
  clear: both;
}

[id=AmzGenresImport] form > div:nth-child(n+2) {
  display: flex;
}

[id=AmzGenresImport] [type=url] {
  flex: 1 1 100%;
}

[id=AmzGenresImport] [type=submit] {
  flex: 1 0 auto;
  margin-left: 1rem;
}