.video__set__edit__form {
  margin: 4rem auto;
  padding-bottom: 5rem;
}

.video__set__edit__form label {
  display: block;
}

.video__set__edit__form > div > div{
  display: flex;
}

.video__set__edit__form input {
  flex: 1 1 80%;
  margin-right: 1rem;
}

.video__set__edit__form button {
  margin-right: 1rem;
  flex: 1 1 80px;
}

.video__set__edit__error {
  color: var(--brand-primary-dark);
  margin: 1rem 0;
}

.video__set__edit__form [type=reset] {
  background: hsl(0, 0%, 90%);
  border: 1px solid hsl(0, 0%, 90%);
}