.reports__channeluse__search {
  display: flex;
}

.reports__channeluse__search input {
  width: 100%
}

.reports__channeluse__search {
  align-items: flex-end;
  align-items: end;
}

.reports__channeluse__search > div {
  flex: 1 1 auto;
}

.reports__channeluse__search .btn {
  padding: .9rem 2.4rem;
}

.reports__channeluse__search [type=reset] {
  margin-left: 1rem;
}

.vidthumb__and__title {
  display: inline-flex;
  align-items: center;
  justify-items: space-between;
}

.ReportsChannelUsageResultPanel__thumb {
  width: 7.5rem;
  height: 5.625rem;
  margin: 0 1rem 0 0
}