.login {
  margin: auto;
  margin-top: 4rem;
  padding: 2rem;

  width: 60rem;
  border: .4rem solid var(--brand-primary-dark);
  border-radius: 10px;

  background: #f3f3f3;
}

.login-logo {
  margin: 2rem auto;
  width: 440px;
  height: auto;
}

.login label {
  display: block;
}

.login input {
  display: block;
  width: 100%;
}

.login__buttons {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 3rem;
}
