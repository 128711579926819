[id=DestinationsToggleView] {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
  position: relative;
  padding: 0 0 2rem ;
}

[id=DestinationsToggleView] > * {
  font-weight: 450;
}

[id=DestinationsToggleViewForm] {
  display: inline-flex;
  margin-left: 1rem;
}

[id=DestinationsToggleViewForm] [type=radio] {
  position: absolute;
  left: -999rem;
}

[id=DestinationsToggleViewForm] *:focus ~ label {
  filter: drop-shadow(0 0 2px var(--brand-primary-dark));
}

[id=DestinationsToggleViewForm] label {
  cursor: pointer;
  font-weight: inherit;
  padding: 1rem 2rem;
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(220,220,220,1) 47%, rgba(211,211,211,1) 100%);
  border: solid #999;
  margin: 0;
}

[id=DestinationsToggleViewForm] div:first-child label {
  border-radius: .5rem 0 0 .5rem;
  border-width: 1px;
}

[id=DestinationsToggleViewForm] div:first-child [type=radio]:checked ~ label {
  background: radial-gradient(circle at 190% 50%, rgba(0,0,0,.5) 20%, transparent 58%),
              linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(211,211,211,1) 47%, rgba(220,220,220,1) 100%);
}

[id=DestinationsToggleViewForm] div:last-child [type=radio]:checked ~ label {
  background: radial-gradient(circle at -90% 50%, rgba(0,0,0,.5) 20%, transparent 58%),
              linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(211,211,211,1) 47%, rgba(220,220,220,1) 100%);
}

[id=DestinationsToggleViewForm] div [type=radio]:disabled ~ label {
  opacity: .5;
}

[id=DestinationsToggleViewForm] div:last-child label {
  border-radius: 0 .5rem .5rem 0;
  border-width: 1px 1px 1px 0px;
}