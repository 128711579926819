dialog {
  border: 0;
  box-shadow: 0 0 5px rgba(0,0,0, .5);
  height: max-content;
  padding-inline: 3rem;
  padding-block: 3rem 6rem;
  width: 80%;
  /* So that it lays on top of Bootstrap based modals */
  /*z-index: 1051;*/
}

.dialog__body {
  background: #fff;
  max-height: 100%;
  min-height: 50%;
  margin: auto;
  min-width: 80rem;
  max-width: 1150px;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
  width: 90%;
  z-index: 2;
}

dialog::backdrop {
  background-color: rgba(0,0,0,.75);
}

.dialog__body__close {
  background-color: transparent;
  border: 0;
  padding: 0;
  height: auto;
  width: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
}

.dialog__body__close svg {
  display: block;
  width: inherit;
  height: inherit;
}

.dialog__body__close:hover polygon{
  fill: #999
}

dialog h2 {
  margin-top: 0
}