.fancy__chex {
  position: relative;
}

.fancy__chex label {
  margin: 0;
  display: inline-flex;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.fancy__chex [type=checkbox] {
  position: absolute;
  opacity: 0;
  left: -9999rem;
}

.fancy__chex label::before {
  border-radius: .3rem;
  height: 2rem;
  width: 2rem;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 1px #3330;
  background: #fff;
  content: ' ';
  text-align: center;
  margin-right: 1rem;
}

.fancy__chex [type=checkbox]:checked + label::before {
  content: '𐄂';
  font-size: 3.5rem;
  line-height: .6rem;
  background: #3b99fc;
  color: #fff;
  speak: none;
}

.fancy__chex [type=checkbox]:checked:disabled + label::before {
  opacity: .5;
  color: rgba(255,255,255,.8);
}

.fancy__chex:focus-within [type=checkbox] + label::before {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}


@supports (filter: saturate(0)) {
  .fancy__chex [type=checkbox]:checked:disabled + label::before {
    filter: saturate(0);
    opacity: 1;
  }
}

