[id=CheckForDuplicatesModal] h2 {
  margin-bottom: 2rem;
}

.check_dupes__modal {
  height: 40rem;
}

[class^=CheckForDuplicatesModal__table] {
  width: 100%;
  border: 1px solid var(--border-color);
  margin: 3rem 0;
}

[class^=CheckForDuplicatesModal__table] th {
  font-weight: bold;
}

[class^=CheckForDuplicatesModal__table] thead {
  border-bottom: 2px solid var(--border-color);
}

[class^=CheckForDuplicatesModal__table] th,
[class^=CheckForDuplicatesModal__table] td {
  border: 1px solid var(--border-color);
  padding: 1.5rem 1rem;
}

[class^=CheckForDuplicatesModal__table] th:nth-child(n+2),
[class^=CheckForDuplicatesModal__table] td:nth-child(n+2) {
  text-align: center;
  overflow: hidden;
}

[class^=CheckForDuplicatesModal__table] .note,
[id=CheckForDuplicatesModal] label {
  display: block;
  font-weight: normal;
}

[id=CheckForDuplicatesModal] .button__group {
  text-align: right;
}

[class^=CheckForDuplicatesModal__table] [type=checkbox] {
  transform: scale(1.5);
}

[id=CheckForDuplicatesModal] .LoaderSimple {
  align-items: stretch;
  background: rgba(255,255,255,.8);
  height: 100%;
}

[id=CheckForDuplicatesModal] .LoaderSimple svg {
  margin: auto;
  width: auto;
  height: 100%;
}
