.form__input_btn {
  display: grid;
  grid-template: "label ."
                 "input button";
  grid-template-columns: auto max-content;
  gap: 0 1rem;
}

.form__input_btn label {
  grid-area: label;
}
.form__input_btn input {
  grid-area: input;
}

.form__input_btn button {
  grid-area: button;
}


.GroupsChannelInfo__logo {
  padding: 3rem 2rem 2rem 2rem;
  border-width: 1px 0;
  margin: 0 0 2rem;
}