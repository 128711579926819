.DragSortList {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  transition: all linear 1s;
}

.DragSortList [draggable] {
  margin-inline-start: 0rem;
}

.DragSortList .dragsortlist__is_dragging * {
  pointer-events: none;
}

.DragSortList .dragsortlist__dragged_item {
  background: orange;
  user-select: none;
}

.DragSortList .dragsortlist__dragged_item * {
  pointer-events: none;
}
