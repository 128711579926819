.search__form__daterange {
  flex: 0 1 auto;
  margin: 0 2rem 0 0;
}

.search__form__daterange label {
  position: absolute;
  left: -9999px;
}

.search__form__daterange .destination-date {
  margin: 0;
}

.search__form__daterange legend {
  border: 0;
  font: inherit;
  font-weight: bold;
  margin: 0 0 5px;
}

.search__form__daterange legend + div {
  display: flex;
  align-items: center;
}

.search__form__daterange .input-date {
  flex: 1 1 auto;
}

.search__form__daterange .input-date + :not(.input-date) {
  margin: 0 1rem;
}
