.channel__list__item,
.channel__list__item:focus,
.channel__list__item:hover {
  color: inherit;
  text-decoration: none;
}

.channel__list__item:focus,
.channel__list__item:hover {
  border-color: var(--brand-primary);
  background: whitesmoke;
}

.channel__list__item ul {
  border-top: 1px solid var(--border-color);
  margin: 2rem 0;
  padding: .5rem 0;
}

.channel__thumb {
  display: block;
  height: auto;
  margin: auto;
  width: 50%;
}