.useradmin__table table {
  table-layout: auto;
  width: 100%
}

.useradmin__edit__controls {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem 0 .5rem;
}

.useradmin__edit__controls .btn {
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0 .5rem;
}

.btn__user--activate {
  min-width: 10rem;
}

[id=UserAdminAlertMessage] .alert__body {
  display: grid;
  align-content: center;
  justify-content: center;
}
