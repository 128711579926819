.s__e__display {
  font-weight: bold;
  margin: 1rem 0;
  display: flex;
  align-items: baseline;
}

.s__e__display input {
  width: 10ch;
}

.s__e__display span {
  text-transform: capitalize;
  padding-right: .5rem
}

.s__e__display span:not(:first-child):before {
  content: ', ';
  padding-right: .5rem
}

.s__e__display button {
  margin-left: 1rem;
  padding: 6px 12px;
}

.s__e__display [type=button] {
  font-weight: bold;
  text-decoration: underline;
  border: 1px solid transparent;
}

.s__e__display .btn[hidden] {
  display: none;
}