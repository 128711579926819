.toggle__switch {
  display: inline-flex;
  margin-left: 1rem;
}

.toggle__switch, .toggle__switch__form {
  align-items: center;
  display: flex;
  margin: 2rem 0;
}

.toggle__switch__form fieldset {
  border: 0;
}

.toggle__switch__group {
  align-items: center;
  display: flex;
}

.toggle__switch__group legend {
  font-size: inherit;
  border: 0;
  margin: 0 1rem 0 0;
  white-space: nowrap;
}

.toggle__switch fieldset {
  font-weight: 450;
}

.toggle__switch [type=radio] {
  position: absolute;
  left: -999rem;
}

.toggle__switch__group *:focus ~ label {
  filter: drop-shadow(0 0 2px var(--brand-primary-dark));
}

.toggle__switch__group label {
  cursor: pointer;
  font-weight: inherit;
  padding: .5rem 1.5rem;
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(220,220,220,1) 47%, rgba(211,211,211,1) 100%);
  border: 1px solid #999;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.toggle__switch__group div:first-of-type label {
  border-radius: .5rem 0 0 .5rem;
  border-width: 1px;
}

.toggle__switch__group div:nth-of-type(n+2) label {
  border-width: 1px 1px 1px 0;
}

.toggle__switch__group div:last-of-type label {
  border-radius: 0 .5rem .5rem 0;
  border-width: 1px 1px 1px 0px;
}

.toggle__switch__group div:first-of-type [type=radio]:checked ~ label {
  background: radial-gradient(circle at 190% 50%, rgba(0,0,0,.5) 20%, transparent 58%),
              linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(211,211,211,1) 47%, rgba(220,220,220,1) 100%);
}

.toggle__switch__group div:nth-of-type(n+2) [type=radio]:checked ~ label {
  background: radial-gradient(circle at 190% 50%, rgba(0,0,0,.8) 10%, transparent 58%),
              radial-gradient(circle at -95% 50%, rgba(0,0,0,.8) 10%, transparent 58%),
              linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(211,211,211,1) 47%, rgba(220,220,220,1) 100%);
}

.toggle__switch__group div:last-of-type [type=radio]:checked ~ label {
  background: radial-gradient(circle at -90% 50%, rgba(0,0,0,.5) 20%, transparent 58%),
              linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(211,211,211,1) 47%, rgba(220,220,220,1) 100%);
}

.toggle__switch__group [type=radio]:disabled ~ label {
  opacity: .5;
}
