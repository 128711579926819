.video__item__rating__unrated {
  filter: saturate(0);
}

div.results__list__item--interstitial {
  background: hsl(17, 100%, 90%);
  border-color: var(--brand-primary);
}

:is(.draggable, [draggable]) {
  cursor: grab;
}

.draggable :not(button) {
  cursor: inherit;
}

.video__draggable {
  background: rgba(255, 255, 255, .8);
  border: solid .2rem hsl(0, 0%, 87.5%);
	border-radius: .8rem;
  padding: 1rem;
  position: relative;
}

/* Also see VideoDisplay styles */
.video__display--grid .video__draggable {
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-areas: "a b"
                 "c c"
                 "d d"
                 "e e"
                 "f f";
}

.results__list__item__title {
  background: transparent;
  border: 0;
  height: 65px;
  line-height: 1.5;
  padding: 0;
  margin: .5rem 0 1rem;
  text-align: left;
  width: 100%;
  word-break: break-all;
}

.videoThumb,
.video__item__thumb {
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  background: transparent;
  border: 2px solid hsl(0, 0%, 87.5%);
  border-radius: 6px;
  padding: 0;
  position: relative;
  width: 100%;
}

.video__item__thumb:hover .youtube__play__icon,
.videoThumb:hover .youtube__play__icon {
  opacity: .5;
}

.video__item__thumb .youtube__play__icon,
.videoThumb .youtube__play__icon {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  width: 6rem;
  height: auto;
}

.youtube__play__icon {
  pointer-events: none;
}

.video__item__thumb img,
.videoThumb img {
  display: block;
  margin: 0;
  height: auto;
  width: 100%;
  aspect-ratio: 4/3;
}

[id=DestinationsToggleView] + *:not(.video__display--listview) .video__item__thumb img {
    min-height: 20rem;
}

.video__drag__item__duration {
	margin: 1rem 0;
  padding: 1rem 0;
	color: black;
	text-align: center;
  border-top: 1px solid var(--brand-primary);
  border-bottom: 1px solid var(--brand-primary);
  font-weight: bold;
}

.video__drag__item__data {
  font-weight: 500;
  max-width: 90%;
  margin: 1rem auto;
}

.video__drag__item__data li {
  display: grid;
  grid-template-columns: .6fr 2fr;
  margin: .5rem 0;
  gap: .5rem;
}

.video__drag__item__data span {
  font-weight: normal;
}

.video__item__delete {
  text-align: center;
  display: block;
}

.video__list--listview .video__item__delete  {
  text-align: right; /* For older browsers */
  text-align: end;
}

.video__draggable__ordinal {
  align-items: center;
  justify-content: center;
}

.video__display--grid .video__draggable__ordinal {
  grid-area: a;
  font-weight: 500;
  display: flex;
  background: hsla(0, 0%, 87.5%, .5);
  height: 3rem;
  border-radius: 100%;
}

.video__display--grid .results__list__item__title {
  grid-area: b;
  padding: 0 .5rem;
}

.video__display--grid .video__item__thumb {
  grid-area: c;
}

.video__display--grid .video__drag__item__duration {
  grid-area: d;
}

.video__display--grid .video__drag__item__data {
  grid-area: e;
}

.video__display--grid .video__item__delete {
  grid-area: f;
}


.video__display--listview .draggable .video__draggable {
  border-width: 1px 0 1px;
  border-radius: 0;
}

.video__display--listview .draggable:first-child .video__draggable {
  border-top-width: 2px;
}

.video__display--listview .draggable:last-child .video__draggable {
  border-bottom-width: 2px;
}

.video__display--listview .video__draggable__ordinal {
  grid-area: f;
}

.video__display--listview .youtube__play__icon {
  width: 3.5rem;
}

.video__inactive {
  border-color: var(--error);
}
