
.video__badge {
	display: inline-block;
  padding: .3rem .5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 17ch;
  line-height: 1;
  border-radius: .3rem;
  background: #eee;
}

.video__badge--large {
  max-width: unset;
  width: auto;
}

.video__meow--okay,
.video__crt--okay {
  color: var(--approved);
  background: var(--approved-badge);
}

.video__meow--notok,
.video__crt--notok {
  color: var(--rejected);
  background: var(--rejected-badge);
}

.video__meow--escalate,
.video__crt--escalate {
  color: var(--escalate);
  background: var(--escalate-badge);
}
