.export__csv button {
  display: flex;
  align-items: center;
}

.export__csv .LoaderSimple {
  background: transparent;
  width: auto;
  height: 1.8rem;
  margin: 0 0 0 .5rem
}

.export__csv svg {
  width: inherit;
  height: inherit;
  transform: none;
}

.export__csv rect {
  fill: #2d3a42;
}