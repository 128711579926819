.ModalAlert {
  border: 0;
  box-shadow: 0 0 5px rgba(0,0,0, .5);
  height: auto;
  height: 50rem;
  position: fixed;
  width: 100%;
  /* So that it lays on top of Bootstrap based modals */
  z-index: 1051;
}

.alert__body {
  background: #fff;
  margin: auto;
  width: 80rem;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
  z-index: 2;
}

.ModalAlert::backdrop {
  background-color: rgba(0,0,0,.75);
}

.dialog__body__close {
  background-color: transparent;
  border: 0;
  padding: 0;
  height: auto;
  width: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
}

.dialog__body__close svg {
  display: block;
  width: inherit;
  height: inherit;
}

.dialog__body__close:hover polygon{
  fill: #999
}

.ModalAlert h2 {
  margin-top: 0
}

[id=UserAdminAlertMessage][open] {
  display: flex;
}