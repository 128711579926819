[id=AddVideoByURL] textarea.form-control {
  height: 20rem;
}


[id=add_videos_by_url_form] {
  margin: 2rem 0 0;
  position: relative;
}

[id=add_videos_by_url_urls] {
  border: 2px solid #ccc;
  display: block;
  resize: vertical;
  width: 100%
}

[id=add_videos_by_url_urls]:focus {
  background: #ff02;
}

[id=add_videos_by_url_urls] + span {
  display: block;
  font-style: italic;
  padding-top: .5rem;
}

[id=add_videos_by_url] div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.LoaderSimple.add__by__url__loading {
  display: flex;
  height: 100%;
  background: rgba(255,255,255,.5);
  position: absolute;
  width: 100%;
}

.addvideos__toggle {
  margin: 0 1rem 0 0;
}

[class^='add_channels_indicator__'] {
  width: 3rem;
}

[class^='add_channels_indicator__'] svg {
  display: block;
  width: 100%;
  height: auto;
}