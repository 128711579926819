#curationContent {
  width:            200%;
	height:           auto;

	position: relative;
	left: 0px;

	display: flex;
	flex-wrap: nowrap;
	align-content: space-around;
}

#curationMenu
{
	position: relative;
	width:            50%;
	height:           auto;
	background-color: white;
	float: left;
}

#curationVideos
{
	position: relative;
	width:            50%;
	height:           auto;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-content: space-around;

	float: right;
	left: 0;
}

.groupEditHeader
{
	position: relative;
	overflow: hidden;
	display: inline-block;
	float: left;

	height: auto;
	padding: 6px;
	margin: 1px;
	background-color: white;
	border-style: solid;
	border-width: 2px;
	border-color: hsl(0, 0%, 87.5%);
	border-radius: 8px;
}

.groupHeaderParent {
	background-color: white;
}

.groupHeader {
  padding-bottom: 7.95rem;
}

.groupHeader.form__single__input {
  border-width: 0;
  padding: 2rem;
  margin: 3rem 0;
  background: var(--alternate);
}
.groupRowBtns
{
	padding: 4px;
}

.bottomSpace_6px
{
	margin-bottom: 6px;
}

.floatRight
{
	float: right;
}

.groupsTableRow
{
	line-height: 38px;
}


[id=curationContent][style] [id=SearchChannelsList]{
  -moz-transform: translate(25%, -50%);
}

.channel__meta_data li {
  display: grid;
  grid-template-columns: 2fr 3fr;
  padding: .25rem 0;
}