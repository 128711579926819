[id=RegionLangDisplay] {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 1rem;
}

[id=RegionLangDisplay] i {
  font-style: normal;
  font-size: 2rem;
  margin-right: 1rem;
}

[id=RegionLangDisplay] .btn__link {
  text-decoration: underline;
  margin: 0 1rem 0 3rem;
}