.VideoModal[open] {
  border: 1px solid var(--border-color);
  border-radius: .8rem;
  display: grid;
  grid-template-rows: auto auto auto;
  min-width: 670px;
  width: 60%;
}

.VideoModal::backdrop {
  background: rgba(0,0,0,.7);
}

.video__modal__close {
  background: transparent;
  border: transparent;
  justify-self: end;
}

.video__info__container {
  padding: 2rem 0;
  border: solid #d0d9df;
  border-width: 1px 0;
  margin: 1rem 0;
}

.video__info__channel li {
  display: grid;
  grid-template-columns: 18rem auto;
  margin: .5rem 0
}

.video__info__vid {
	width:  640px;
	height: 372px;
	margin: 0 auto 2rem;
}

.video__info__vid iframe {
  width: inherit;
  height: inherit;
}

.video__info__in_sys {
  text-align: center;
  margin: 2rem auto;
}

.video__info__field {
  padding: 2rem 0;
}

.video__info__field label {
  display: block;
  font-size: 1.6rem;
}

.video__info__field .form__input_button_group {
  display: flex;
}

.video__info__field h5 {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 0 2rem 0;
}

.video__info__field:nth-child(odd) {
  border: 2px solid #d0d9df;
  border-width: 2px 0;
}

.video__info__field:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.video__info__add_to_destination.video__info__field {
  position: relative;
  border-top: 0;
}

.video__info__add_to_destination::after {
  content: ' ';
  display: table;
  clear: both;
}

.video__info__add_to_destination legend {
  border: 0;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: .5rem;
}

.video__info__add_to_destination .btn {
  float: right;
}

.video__info__custom_tags label {
  display: block
}

.video__form__ratings label {
  display: block;
}

.video__info__chann h5 {
  font-weight: bold
}

.video__info_changroup {
  margin-top: 2rem;
}

.video__info__crt {
  align-items: flex-end;
}

.video__info__crt .ava-input-select {
  flex: 1 1 auto;
  margin-right: 1rem;
  margin-left: 0;
}

.video__info__crt button {
  padding: 10px 12px;
}

[id=VideoInfo] .modal-title {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.video__info__vid_id:link {
  color: #555;
  font-size: 1.4rem;
  margin-right: 2rem;
}

.video__info__vid_id b {
  color: #555;
  font-size: 1rem;
}

.video__stats__list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
}

.video__stats__list b,
.video__stats__list abbr {
  font-weight: 510;
}

.video__stats__list > li {
  display: inherit;
}

.video__stats__list > li:not([class^="video__stats__list_"]) {
  grid-template-columns: 1.25fr 2.75fr;
}

.video__stats__list > li:nth-child(odd) {
  grid-column: 1 / 7;
}

.video__stats__list > li:nth-child(even) {
  grid-column: 7 / 13;
}

.video__stats__list > li:nth-child(11) {
  grid-column: 1 / 13;
  grid-template-columns: 1fr 4fr;
  margin: 1rem 0
}


.video__stats__list > li:nth-child(n+12) {
  grid-template-columns: 1fr 3fr;
}

.video__stats__list > li:nth-child(12) {
  grid-column: 1 / 7;
}

.video__stats__list > li:nth-child(13) {
  grid-column: 7 / 13;
}

.video__stats__list > li[class^="video__stats__list_"] {
  grid-column: 1 / 13;
  align-content: start;
  margin: 1rem 0;
}

.video__info__vs_badge {
  align-content: center;
  background: var(--button-bg);
  border-radius: 100rem;
  color: #000;
  display: inline-flex;
  margin: .25rem;
  padding: .25rem .75rem;
}

.video__info__vs_badge:hover,
.video__info__vs_badge:focus {
  background: var(--button-bg--hover);
  color: inherit;
  border-color: var(--brand-primary);
}

.video__info__eplist {
  min-height: 7rem;
}

.video__info__setname {
  display: inline;
}

.video__info__vs_badge b {
  font-weight: 300;
  white-space: nowrap;
}

[class^="video__stats__list_"] > div {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 10rem;
  position: relative;
}

.video__stats__list_vt > div > div {
  width: 100%;
  min-height: 10.5rem;
}

[class^="video__stats__list_"] > *::before,
[class^="video__stats__list_"] > *::after {
  content: '\00a0';
  display: block;
  height: .8rem;
  width: 100%;
}

.video__modal__destinations__select {
  width: 100%;

  label {
    font-weight: 400;
  }

  &.ava-input-select {
    margin-left: unset;
  }
}
