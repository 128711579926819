.switch__projects {
  color: #fff;
  background: var(--brand-secondary-dark-35);
  border: none;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  border-radius: .5rem;
  margin-left: .5rem;
}

.switch__projects svg {
  margin: 0 .5rem 0 0;
  pointer-events: none;
}

.switch__projects path {
  fill: #fff;
}