.filter__sort, .filter__search {
  margin-bottom: 15px;
}


.filterSelect {
  float: right;
  width: 60%;
}

[id=filter__component__hint] {
  font-size: 1.2rem;
  margin: 0;
  padding: .5rem 0 2rem;
}

[id=filter__component__hint][hidden] {
  xdisplay: block !important;
  visibility: hidden;
  padding: 0;
}

.filter__categories,
.filter__vid {
  margin-bottom: 1.5rem
}

.filter__component .ava-input-select {
  display: block;
  margin-left: 0;
}

.sort__order__icon {
  font-size: 2rem;
  display: inline-flex;
  padding: 0 1rem;
}