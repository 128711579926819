.stations__view {
  max-width: 128rem;
  margin: auto;
}

.stations__view > header {
  display: flex;
  align-items: center;
}

.stations__view header div {
  flex: 1 1 auto;
}


.stations__view header div:first-child {
  flex-basis: 90%;
}

.stations__list {
  margin: 3rem 0;
}

.stations__list li:nth-child(even) {
  background: var(--alternate);
}

.stations__list a {
  display: block;
  font-weight: bold;
  padding: 1.5rem 1rem;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.stations__list a:hover {
  background: hsla(201.7, 50.4%, 73.1%, .5);
  color: #000;
}