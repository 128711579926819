html {
  font-size: 10px;
}

/*
Somewhere in the tangle of Bootstrap CSS, the [hidden]
attribute is being overridden by a display:inline-block rule
for .btn. This prevents that.
*/

[hidden] {
  display: none !important;
}

.pageBtn {
	height: 100%;
	cursor: pointer;
	text-align: center;
	background-color: white;
  border: none;
	border-radius: 4px;
  flex: 1 1 50%;
	padding: 7px;
  margin: .5rem;
}

.pageBtn:hover {
	background-color: #df6331;
	color: white;
}

.pageBtnDisabled {
	pointer-events: none;
	opacity: .5;
}

#content {
	display: block;
	margin: auto 2rem 4rem;
	min-height: 500px;
  box-sizing: border-box;
}

#root {
	position: relative;
	margin: 1rem;
  min-width: 100rem;
	padding:  0;
}

img {
	max-width:  100%;
}

a {
	text-decoration: none;
}

.btn-success {
	color:            #000 !important;
	background-color: hsl(18.9, 98.1%, 60%) !important; /* Should probably change to hsl(17.2, 73.1%, 40%) for better contrast */
	border-color:     hsl(18.9, 98.1%, 60%) !important;
}

.btn-success:hover {
  background-color: hsla(18.9, 98.1%, 58%, .5) !important;
  border-color: hsla(18.9, 98.1%, 58%, .5) !important;
}

.btn-success:disabled {
  filter: saturate(.5);
}

.divTransition {
	transition: 0.5s;
}

.centerTxt,
.text__center {
	text-align: center;
}

.text__right {
	text-align: right;
}

.text__nowrap {
  white-space: nowrap;
}

.paging {
	display: flex;
  background: hsl(0, 0%, 87.5%);;
  min-width: 110rem;
}

.itemsBadge {
	display:        inline-block;
	font-weight:    500;
	white-space:    nowrap;
}

.listItem {
	display: inline-block;
	margin-bottom: 6px;
	width: 100%;
}

.btnDisabled {
	pointer-events: none;
	opacity: .5;
}

.filter__component {
  display: flex;
	width: 100%;
  margin-bottom: 1rem;
}

.filter__component form {
  padding: 1rem 3rem 2rem;
  flex: 0 0 50%;
}


.filter__component form:first-of-type {
  padding: 1rem 3rem 1rem 0;
  border-right: 1px solid var(--button-destructive);
}

.filterInputBox {
	height:       33px;
	font-size:    18px;
	color:        #494a45;
}

.marginTop6px {
	margin-top: 6px;
}

.textNormal {
	white-space: normal;
	text-align: left;
}

.modalHeaderBtns {
	text-align: right;
}

.Select-input input {
	width: 100% !important;
	padding: 0 !important;
}

.userEditSelect {
	width: 100%;
	float: left;
	height:       40px;
	font-size:    16px;
	color:        #494a45;
}

.button__group .btn + .btn {
  margin-left: .5rem;
}

.button__stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.button__stack .btn + .btn {
  margin-top: 1rem;
}

.table__list__modal .modal-header {
  padding: 15px 15px 0;
}

.table__list__controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.table-list {
  border-collapse: collapse;
  border: 1px solid #ccc;
  margin: 1rem 0 0;
  width: 100%;
}

.table-list th {
  font-weight: bold;
  white-space: nowrap;
}

.table-list label {
  cursor: pointer;
  display: block;
  font-weight: normal;
  margin: 0;
}

.table-list thead {
  border-bottom: 2px solid #ccc;
}

.table-list th,
.table-list td {
  border: 1px solid #ccc;
  padding: 1rem
}

.table-list th:first-of-type {
  width: 5%;
}

.channel__list__table th:last-of-type {
  width: 15%;
  text-align: center;
}

.channel__list__table td:last-of-type {
  text-align: right;
}

.table-list tbody tr:nth-child(odd)  {
  background: #f9f9f9;
}

.table-list td {
  vertical-align: center;
}

.table-list tr:nth-child(n):hover  {
  background: #e4e4e4;
}

.table-list + select {
  margin: 2rem 0;
}

.table__list__perpage label {
  padding-right: 1rem;
}

.table__list__perpage label::after {
  content: ':'
}

.table-list [type=checkbox] {
  margin: 0;
  transform: scale(1.5);
}

.page__pagination {
  clear: both;
  padding-bottom: .5rem;
  width: 100%;
  background: hsl(0, 0%, 87.5%);;
}

/* Single input form fields, such as search, groups, etc. */
.form__single__input {
  padding: 1rem 2rem 2rem;
  border-bottom: 1px solid #df6232;
  margin: 1rem;
}

/* Label up top, input and buttons side-by-side */
.form__label_btn_group {
  display: grid;
  gap: 1rem;
  grid-template: "a a a" auto
                 "b c c" auto / 3fr auto auto;
  align-items: end;
}

.form__label_btn_group label {
  grid-area: a;
}

.form__label_btn_group button {
  padding-top: 8px;
  padding-bottom: 8px;
}
/*
Terrible class names for add/remove/delete buttons on modals
and Search result item components.
*/
.action__button {
  border: none;
  color: #df6331;
  background: #f9fbfd;
  display: inline-block;
  margin: auto;
  padding: 1rem;
}

.form__action_control {
  border-bottom: 1px solid var(--border-color);
  margin: 10px 0;
  padding: 10px 0 20px;
}

.form__action_control .button__group button:nth-child(odd) {
  margin-right: 2rem;
}

.results__list__item {
  padding:          6px;
	margin:           1px;
	background-color: white;
	border-style:     solid;
	border-width:     2px;
	border-color:     hsl(0, 0%, 87.5%);;
	border-radius:    8px;
}

.results__list__item li:not(.nogrid) {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin: .5rem 0;
}

.form__input_button_group span {
  display: flex;
}

.form__input_button_group .btn {
  margin-left: 1rem;
}

.form__input_button_group [type=search],
.form__input_button_group [type=text],
.form__input_button_group [type=url],
.form__input_button_group select {
  flex: 1 0 auto;
}

input:disabled,
select:disabled {
  background-color: #efefef;
  color: #555;
}

.msg__error {
  margin: 1rem 0;
  padding: 1rem;
  font-weight: bold;
  color: hsla(17, 73%, 10%, 1);
  border: 1px solid hsla(17, 73%, 90%, 1);
  background: hsla(17, 73%, 95%, 1);
}

.form__access__label {
  position: absolute;
  left: -9999px;
  height: 0;
}

.form__instructions {
  display: inline-block;
  padding-top: .75rem;
  font-size: .9em;
  font-style: italic;
  color: #555;
}

.form__error {
  color: var(--error);
}

abbr, abbr[title] {
  border-bottom-width: 0 !important;
  font-style: normal;
}

.btn-success:focus,
.btn-success.focus {
  color: #000;
  border-color: hsla(202, 50%, 63%, 1) !important;
}

:required {
  outline: 0;
  box-shadow: none;
}

.trigger__fauxlink {
  background: transparent;
  padding: 0;
  border: 0;
  color: hsl(201.7, 50.4%, 33.1%);
  font-weight: bold;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

@supports not (filter: opacity(0)) {
	.trigger__fauxlink:disabled {
    opacity: .5;
  }
}

.trigger__fauxlink:disabled {
  filter: opacity(.7) saturate(0);
  text-decoration: none;
}

.btn {
  font-weight: 420;
}

.btn__link {
  background: transparent;
  border: none;
  color: #337ab7;
  padding: 0;
  line-height: 1;
}

.btn__link:hover,
.btn__link:focus {
  color: var(--brand-primary-dark);
  text-decoration: underline;
}

.btn--action {
  background: var(--button-bg);
  color: #000;
}

.btn--restore {
  background: var(--escalate-badge);
  color: #000;
  padding: .5rem 1.8rem
}

.btn--restore:hover,
.btn--restore:focus,
.btn--action:hover,
.btn--action:focus,
.btn--destructive:focus,
.btn--destructive:hover {
  background: var(--button-bg);
  background: var(--button-bg--hover);
  color: #000;
}

.btn--destructive {
  background: var(--button-destructive);
}

.btn--request {
  color: var(--brand-secondary-dark-25);
  background: var(--button-destructive);
  border: 0;
  border-radius: .3rem;
  padding: .25rem 1rem;
}


input:focus,
button:focus,
select:focus,
textarea:focus {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.aria__description {
  color: rgba(0,0,0,.8);
  font-size: .9em;
  font-style: italic;
  display: inline-block;
  padding-top: .5rem;
}

.icon {
  display: inline-block;
  margin-right: 1rem;
  opacity: .8;
}

.btn-light {
  background: var(--bg-table-header) !important;
}

.required::after {
  content: '*';
  color: var(--error);
  font-weight: bold;
}

.react-bs-table .table-bordered > thead > tr:first-child > th {
  font-weight: bold;
  vertical-align: top
}

em {
  font-style: italic !important;
}

code {
  color: hsl(17.2, 73.1%, 33.3%);
}

.modal-xl {
  width: 70%;
  max-width: 1100px;
}

.modal {
  z-index: 1049 !important;
}

.modal-header {
  border-bottom-color: transparent;
}

.modal-footer {
  border-top-color: transparent;
}

[aria-hidden="true"] {
  speak: none;
}

.resizable {
  resize: vertical;
}

.resizable--vert {
  resize: vertical;
}

.resizable--horiz {
  resize: horizontal;
}

.form-control {
  color: #000;
}

.text__tbl__align_duration {
  text-align: right;
}

/*---------- Details & Summary ---------*/
/* Firefox mostly */
details ::marker {
  display: none;
}

/* Hack to serve styles to Firefox. It will some day fail, but until it does ... */
@supports (-moz-transform: none) {
  summary::after {
    content: '\25b6';
    text-align: center;
    font-size: .9rem;
  }

  [open] summary::after {
    content: '\25bc';
  }
}

/* Changes position of arrow */
details ::-webkit-details-marker {
  order: 3;
  padding: 1.5rem;
}

/* Hack for Safari 10.1+ that still works, thank goodness! */
@media not all and (min-resolution:.001dpcm) {
  details summary {
    position: relative;
  }

  /* Changes position of arrow */
  details ::-webkit-details-marker {
    position: absolute;
    right: .5rem;
    top: auto;
    bottom: auto;
  }
}

.l__flex__sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l__flex__end_vc {
  justify-content: flex-end;
  justify-content: end;
  align-items: center;
}

.l-shelf-flex50 {
  display: flex;
}

.nav__project {
  background: var(--brand-secondary);
  border-radius: .5rem;
  display: inline-flex;
  font-weight: bold;
  overflow: hidden;
  flex: 1 1 80%;
}

.nav__project > * {
  padding: 1.25rem 2rem;
}

.nav__project a:link {
  color: #000
}

.nav__project a:visited {
  color: #222;
}

.nav__project a.header__selected,
.nav__project a:hover,
.nav__project a:focus {
  background: var(--button-bg--hover);
}

.nav__project a:focus {
  outline-color: var(--brand-primary);
}

.nav__project_label {
  background: var(--brand-secondary-dark-35);
  border-right: 5px solid var(--brand-secondary-dark-25);
  color: white;
}

.nav__project__wrap {
  display: flex;
  margin-bottom: 3rem;
}

.table__note {
  font-weight: normal;
  font-size: 1.2rem;
}

th {
  font-weight: bold;
}

.table__striped {
  border-collapse: collapse;
  width: 100%;
}

.table__striped th,
.table__striped td {
  padding: .8rem;
}

.table__striped tr:nth-child(even) {
  background: var(--alternate);
}

.table__bordered_head thead {
  border-bottom: 2px solid var(--border-color);
}

.msg--success {
  color: var(--approved);
}

.msg--failed {
  color: var(--rejected);
}

.unstyled[title] {
  font-style: normal;
  font-weight: normal;
  border-bottom: 0;
  text-decoration: none;
}

.no-word-break {
  hyphens: none;
  display: inline-block;
}

.bulleted-disc {
  list-style: disc;
  margin: 2rem 0 0 2rem
}

.bulleted-disc li {
  margin: 1rem;
}

.multi_success {
  margin: 3rem 0;
}

.multi_success li {
  display: grid;
  grid-template-columns: 3fr .5fr .25fr;
  padding: 1rem;
  align-items: center;
}

.multi_success--alt li:nth-child( odd ) {
  border: solid var(--border-color);
  border-width: 1px 0;
}

.multi_success--alt li:nth-child( even ) {
  background: var(--alternate);
}
