.reports__vidset__search {}

.reports__vidset__search label {
  clear: both;
}

.reports__vidset__search {
  margin: 0 0 2rem;
  background: var(--alternate);
  padding: 1rem;
}

.reports__vidset__search > div {
  display: flex;
}

.reports__vidset__search [type=text] {
  flex: 0 1 50%;
  margin-right: .5rem;
}

.reports__vidset__search button {
  margin-left: 1px;
}
