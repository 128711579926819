.episode__view__title {
  display: flex;
  align-items: center;
  margin-bottom: 3rem
}

.episode__view__title h1 {
  flex: 1 1 90%
}

