[id=destinationsMenu] {
  margin: 5rem auto 2rem;
  max-width: 130rem;

  /*display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;*/
}

#destinationsContent {
	xwidth: 100%;
}

#destinationsVideos {
	position: relative;
	width:            1000px;
	height:           auto;

  background: #0c0a;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-content: space-around;

	float: left;
	left: 0;
}

#destinationsHeaderParent {
	background-color: white;
}

#destinationsHeader {
	padding:  20px 16px 30px;
}

.groupRowBtns {
	padding: 4px;
}

.bottomSpace_6px {
	margin-bottom: 6px;
}

.floatRight {
	float: right;
}

.addVideosBtn {
	font-size: 20px;
	margin: 3rem 1rem 0 0;
	display: block;
}

.playListBtn {
	margin-right: 6px;
	margin-bottom: 6px;
}


.destinations__metadata_and_status {
  margin: 20px 0;
  padding: 10px 0;
  border: 2px solid var(--alternate);
  border-width: 0 0 2px;
  display: flex;
}

.destinations__comments_reviewed {
	display: flex;
}

.destinations__meta_controls {
	flex: 0 1 28%;
  border-right: 2px solid var(--alternate);
  margin-right: 16px;
}

.destinations__meta_controls__controls {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  
  .playListBtn {
    margin: 0;
  }
}

.destinations__playlist_copy {
	flex: 1 0 487px;
  border-right: 2px solid var(--alternate);
  margin-right: 16px;
  padding-right: 16px;
}

.destinations__playlist_copy input {
  margin-bottom: 1rem
}

.copy-txt
{
	height: 60px;
}

.checkboxLabel
{
	font-weight: bold;
	float: left;
	color: #9a9a9a;
	margin-bottom: 2px;
}

.checkBoxTxt
{
	width: 100%;
	text-align: left;
	font-size: 12px;
	float: left;
}

.destinations__comments {
	flex: 1 1 66%;
  border-right: 2px solid var(--alternate);
  margin-right: 16px;
  padding-right: 16px;
}

.export__csv {
  display: inline-block;
}

.destinations__comments textarea {
  resize: vertical;
  min-height: 50px;
}

.destinations__save {
  display: inline-block;
}

.destinations__disabled__list {
  filter: saturate(0);
}

.destinations__add__video__controls {
  padding-top: 3rem;
  display: flex;
  gap: 1rem;
  justify-content: center;

}

.destinations__view {
  max-width: 130rem;
}

.playlist__title {
  display: flex;
  align-items: center;
  margin: 5rem 0;
}

.playlist__title .icon {
  margin: 0 0 0 2rem;
  font-size: .75em
}

.playlist__title__bar > div {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0
}

.action--alert {
  background: #ffc107;
  background: #ffc107cc;
  border-radius: .4rem;
  font-size: 1.25em;
  display: inline-block;
  line-height: 1.2;
  padding: .7rem 1.25rem;
}

.playlist__rundate {
  margin-top: 2rem;
}

[id=playlist__dupemod] label {
  display: block;
}

.playlist__dupemod_btns {
  display: inline-flex;
  margin-left: 1rem;
}

.playlist__dupemod_btns button {
  margin-left: 1rem;
}

[id=playlist__dupemod] form {
  margin: 4rem auto;
  width: 80%;
}

[id=playlist__dupemod] h3 {
  margin: 3rem 0;
}

.destination_create_rundate--modal {
  margin: 3rem 0;
}

.destination_create_rundate--modal .playlist__dupemod_btns {
  display: flex;
  margin-left: 0;
  align-items: stretch
}

.destinations__check_for_updates {
  margin-right: .5rem;
}