.project__link {
  display: grid;
  background: var(--brand-secondary--80);
  border-radius: .5rem;
  color: #000;
  font-size: 2.8rem;
  font-weight: 500;
  padding: 6rem 2rem 2rem;
  grid-template-columns: auto 12rem;
  align-items: end;
}

.project__link:hover,
.project__link:focus {
  color: #222;
  background: var(--brand-secondary);
}

.project__link:focus {
  box-shadow: inset 0 1px 1px var(--button-bg--hover), 0 0 8px var(--button-bg--hover);
}

.project__icon {
  height: 6.5rem;
  width: auto;
  justify-self: end
}
