[id=AmzClustersAddNew] {
  clear: both;
  float: right;
  width: 100%;
  text-align: right;

  outline: 3px solid #ccc;
}

[id=AmzClustersAddNew]::after {
  content: ' ';
  display: block;
  clear: both;
}
