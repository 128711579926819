
/*VIDEO PAGE*/

/* .videoListItem {
	position:         relative;
	overflow:         hidden;
	flex:             1 1 24.5%;
	height:           424px;

	padding:          6px;
	margin:           1px;
	background-color: white;

	border-style:     solid;
	border-width:     2px;
	border-color:     hsl(0, 0%, 87.5%);;
	border-radius:    8px;
} */

.videoListHolder {
	margin: 1px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}

.videoList {
	display: -webkit-flex;
	display: -webkit-box;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	justify-content:  flex-start;
}

.VideoListGroup {
  padding: .25rem 0;
  width: 100%;
}

.listsBadge {
	display:        inline-block;
	min-width:      10px;
	padding:        3px 7px;
	font-size:      12px;
	font-weight:    bold;
	color:          var(--brand-primary-dark);
	line-height:    1;
	vertical-align: middle;
	white-space:    normal;
	text-align:     left;
}

.listItemScrollingTextBox:not(:empty) {
	overflow-y: scroll;
	width: 90%;
	height: 5rem;
	border: 1px solid var(--border-color);
	box-shadow: 0 0 3px var(--border-color) inset;
	display: block;
  padding: .5rem;
  resize: vertical;
}

#videoHeaderParent {
	background-color: white;
	width:            100%;
  position: relative;
}

#videoHeader {
	margin-left:  16px;
	margin-right: 16px;
	margin-top:   20px;
}

#videoHeader div:first-of-type::after {
	content: ' ';
	display: table;
}

.videoHeaderSelectGroup {
	width: 300px;
	top: 99px;
	right: 122px;
	position: absolute;
}

.getLatestVideosBtn {
	position: absolute;
	top: 10px;
	right: 10px;
}

.importGoogleSheetsBtn {
	float: right
}

.listItemScrollingTextBox.videoScrollingBox {
  padding: .5rem;
}

.video__item__metadata {
  padding: 1rem 0;
  margin: 1rem 0
}

.video__list_search_which {
  margin-left: 1rem;
}

.video__list__modal .close {
  font-size: 2em;
  line-height: 1;
}

.videos__pagination {
  display: flex;
}

.videos__pgcount {
  font-size: 2rem;
  flex: 1 1 50%;
}
