.destinations__playlist {
  margin: 5rem auto 0;
}

.destinations__playlist__table {
  margin: 3rem 0;
}

.destinations__playlist__table th:nth-child(2),
.destinations__playlist__table .duration {
  text-align: right;
  text-align: ':' right;
  width: 50ch;
  padding-right: 4rem;
}

.destinations__playlist__table th:last-of-type,
.destinations__playlist__table td:last-of-type {
  text-align: center;
  width: 30ch;
  white-space: nowrap;
}

.playlist__edit {
  display: inline-block;
  margin: 0 1rem;
}