.bigSearchInputBox
{
	height:       40px;
	font-size:    18px;
	color:        #494a45;
}

.tags
{
	display: inline-block;
	min-width: 10px;
	padding: 3px 0;
	font-weight: 500;
	line-height: 1.4;
	margin-bottom: 4px;
}

.search__form__query label {
  display: block;
}

.search__form__query input {
  display: block;
  width: 100%;
}

.search__form__date__label span {
  display: none;
}

.channel__list__search  {
  display: flex;
  align-items: flex-end;
  margin: 3rem 0 5rem;
}

.channel__list__search .input-text {
  margin-right: 1rem;
  flex: 1 1 50%;
}

.channel__list__search [type=text] {
  width: 100%;
}

.channel__list__search button {
  padding: .8rem 1rem;
}
.channel__list__search label{
  display: block;
}

.result__item__is-in-system {
  border-top: 1px solid var(--brand-primary-dark);
  border-bottom: 1px solid var(--brand-primary-dark);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  margin: 2rem 0;
}
