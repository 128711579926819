.amz__songs__tbl {
  width: 100%;
}

.amz__songs__tbl thead {
  background: #ccc;
}

.amz__songs__tbl th {
  font-weight: bold
}

.amz__songs__tbl th,
.amz__songs__tbl td {
  padding: 1rem;
}

.amz__songs__tbl tr:nth-child(even) {
  background: var(--alternate)
}

.amz__songs__tbl tr:hover {
  background: var(--row-hover)
}

.amz__songs__tbl tr :first-child {
  text-align: center;
}

.amz__songs__tbl img {
  width: 7.5rem;
  height: auto;
  margin: auto;
}

.amz__songs__head h2 {
  font-size: 1.6rem;
  font-weight: 400
}

[id=AmzSongSearch] {
  margin: 3rem 0
}

[id=AmzSongSearch] div + div  {
  display: flex;
}

[id=AmzSongSearch] button {
  flex: 0 0 auto
}

[id=AmzSongSearch] [type=text] {
  flex: 1 1 auto;
  margin-right: .5rem
}

[id=AmzSongAddSongs__form] label,
[id=AmzSongAddSongs__form] textarea {
  display: block;
  width: 100%;
}

[id=AmzSongAddSongs__form] textarea {
  width: 100%;
  height: 50ex
}

[id=AmzSongAddSongs__form] textarea:focus {
  background: #ff02;
}

[id=AmzSongAddSongs__form] .button__group {
  text-align: right;
}

.asin__thumb {
  border: 1px solid #ccc;
  width: 7.5rem;
  height: auto;
}

.asin__table_add {
  margin: 2rem 0;
  width: 100%;
}

.asin__table_add th,
.asin__table_add td {
  padding: .5rem 1rem;
}

.asin__table_add th {
  background: #eee;
  font-weight: bold;
  vertical-align: bottom;
}

.asin__label::before {
  content: ' ';
  display: inline-block;
  height: 5rem;
  width: 5rem;
}

.asin__table_add label[for*=order] {
  display: inline;
  width: auto;
  height: auto;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.asin__table_add [type=number] {
  text-align: right;
  width: 10ch;
}

.asin__table_order {
  width: 10%;
}

.amz__songs__tbl th:first-child {
  width: 5%;
}

.amz__songs__tbl th:nth-child(2) {
  width: 10%;
}

.amz__songs__tbl th:nth-child(3) {
  width: 20%;
}

.amz__songs__tbl th:nth-child(4) {
  width: 25%;
}

.amz__songs__tbl th:nth-child(5) {
  width: 30%;
}

.tbl__sort_trigger {
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: underline;
}

.amz__songs__tbl input {
  margin-right: 1rem;
  text-align: "." right;
  width: 5em;
}

.amz__songs__tbl input:focus {
  background: #ff02;
}