.reports__page {
  padding: 2rem;
  background: #fff;
  min-height: inherit;
}

.reports__page .ava-input-select {
  margin-right: 1rem;
}


.reports__page li {
  font-size: 1.2em;
  margin: 1rem 0 2rem;
}

.reports__page h1 {
  margin: 0 0 20px;
}

.reports__link {
  font-weight: bold;
}

.reports__page label {
  display: block;
}

.reports__destination__search {
  display: flex;
  align-items: flex-start;
}

.destination-date {
  margin-right: 1rem;
}

.destination-date input {
  padding: .75rem 1rem;
}

.destination-date span {
  font-size: 1rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .5);
}

.reports__destination__search button {
  border-radius: 5px;
  padding: .8rem 2rem;
  margin-top: 7px;
  align-self: center;
  flex: 0 0 auto;
}

.reports__table__results {
  margin: 3rem 0;
  min-width: 100%;
}

.reports__table__results thead {
  background: #fff;
  font-weight: bold;
  border-bottom: 2px solid hsl(0, 0%, 87.5%);
  border-top: 2px solid hsl(0, 0%, 87.5%);
}

.reports__table__results tbody tr:nth-child(odd){
  background: whitesmoke;
  border-top: 1px solid hsl(0, 0%, 87.5%);
  border-bottom: 1px solid hsl(0, 0%, 87.5%);
}

.reports__table__results a {
  color: var(--brand-secondary-dark);
  font-weight: bold;
  border-bottom: 1px dotted currentColor;
  padding: 3px 3px 2px;
}

.reports__table__results a:hover {
  color: hsl(208, 56%, 46%);
  border-bottom: 1px solid var(--brand-primary-dark);
  text-decoration: none;
  background: hsla(208, 56%, 46%, .2);
}

.reports__table__overflow {
  height: 800px;
  overflow: auto;
  position: relative;
}

.reports__table__overflow::before {
  content: ' ';
  display: block;
  box-shadow: 1px 1px 5px 1px hsla(200, 0%, 0%, .7);
  position: absolute;
  top: 0;
  width: 100%;
  padding: 5px;
}

.reports__table__results th,
.reports__table__results td {
  padding: 2rem 1rem;
}
.reports__table__results th {
  font-size: 1.25em;
  font-weight: bold;
  padding-top: 2rem;
  vertical-align: bottom;
}

.reports__results__viddest th:last-of-type {
  width: 15%;
}

.reports__results__viddest th:last-of-type,
.reports__results__viddest td:last-of-type {
  text-align: right;
}

.reports__results__viddest td:last-of-type {
  padding-right: 3rem;
}

.reports__dashboard__count {
  align-items: baseline;
  border-top: 2px solid hsla(202, 50%, 73%, .8);
  border-bottom: 2px solid hsla(202, 50%, 73%, .8);
  background: hsla(202, 50%, 73%, .25);
  display: flex;
  font-size: 28px;
  margin: 4rem 0 4rem;
  padding: 2rem 1rem;
}

.reports__dashboard__count__num {
  font-size: 36px;
  font-weight: bold;
  padding: 0 .5ch;
}
