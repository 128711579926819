.stations__series__table {
  margin-top: 2rem;
  width: 100%;
}

.stations__series__table thead,
.stations__series__table tr:nth-child(even) {
  background: var(--alternate);
}

.stations__series__table th {
  font-weight: bold;
}

.stations__series__table th,
.stations__series__table td {
  padding: 1rem;
}

.stations__series__table button:not(:last-of-type) {
  margin-right: 1rem;
}

.stations__series__table td:last-of-type {
  width: 48rem;
}

.stations__btn__editseries {
  background: #fff3;
  border: 2px solid var(--border-color);
}

.stations__btn__editseries:hover {
  background: hsla(18.9, 98.1%, 58%, .5);
  border-color: hsla(18.9, 98.1%, 58%, .5);
}

.stations__series__table a {}