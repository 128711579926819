[id=DestinationsIncludeInactive] label {
  margin-left: 1rem;
}

[id=DestinationsIncludeInactive] input {
  transform: scale(1.5);
}

[id=DestinationsSearch] [for=DestinationsIncludeInactive__toggle] {
  font-weight: 450;
  font-size: 1.4rem;
  margin-left: 1rem;
}

[id=DestinationsIncludeInactive] {
  margin-top: .5rem;
}
