.video__display :is(.DragSortList, .List) {
  display: grid;
}

.video__display--grid :is(.DragSortList, .List) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

@media screen and (min-width: 1600px) {
  .video__display--grid .DragSortList {
    grid-template-columns: repeat(6, 1fr);
  }
}

.video__display--grid .dragged {
  max-width: 29rem;
  width: 1fr;
}

.video__display--listview {
  max-width: 80rem;
  margin: auto;
}

.video__display--listview * {
  margin: 0;
  text-align: left;
}

.video__display--listview .DragSortList {
  display: unset;
}

.video__display--listview li + li {
  margin-block-start: 2rem;
}

.video__display--listview .results__list__item__title {
  min-height: 0;
  height: auto;
}

.video__display--listview .video__draggable {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 3ch 10rem 1fr 1fr;
  grid-template-areas: "f a b b"
                       "f a c c"
                       "f a d d"
                       "f a e e";
  align-items: center;
}

.video__display--listview .results__list__item__title {
  grid-area: b;
}

.video__display--listview .video__item__thumb {
  grid-area: a;
}

.video__display--listview .video__drag__item__duration  {
  border: 0;
  grid-area: c;
  padding: 0;
}

.video__display--listview .video__drag__item__data  {
  grid-area: d;
}

.video__display--listview .video__item__delete  {
  grid-area: e;
  text-align: right;
  text-align: end;
}

.video__display--listview img {
  min-width: 0rem;
  height: auto;
}

.video__display--listview .video__drag__item__data li {
  grid-template-columns: 10rem 2fr
}

.video__display--listview .dragged {
  max-width: 80rem;
}

.video__display--listview .video__drag__item__data {
  display: none;
}

.video__display--listview .video__item__order {
  grid-area: f;
}
