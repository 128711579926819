.searchListItem {
  position: relative;
}

.results__list__item:not(.channel__list__item):focus-within {
  border-color: var(--brand-primary);
  background: whitesmoke;
}

.show__video__trigger {
  display: block;
  background: transparent;
  border-radius: 0;
  border: 0;
  padding: 0;
  margin: auto;
  position: relative;
  overflow: hidden;
  height: 212px;
  width: 100%;
}

.show__video__trigger img {
  width: 100%;
  height: auto;
}

.show__video__title {
  text-align: left;
  font-weight: bold;
  padding: 0;
  border: 0;
  background: transparent;
  border-radius: 0
}

.show__video__title h5 {
  line-height: inherit;
}


.result__item__add_to_system {
  background: var(--alternate);
  color: var(--brand-primary);
  font-size: 2rem;
  padding: .5rem;
  margin: 2rem 0 !important;
  width: 100%;
}

.result__item__add_to_system:hover,
.result__item__add_to_system:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
  background: hsla(18.9, 98.1%, 58%, .5);
  color: #000
}

.video__in__system__icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4rem;
  height: auto;
}

.video__in__system__icon [id=creative] {
  display: none;
}